/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoginResponse } from "@/domain/login.model";
import { IUser } from "@/interfaces/user";
import httpClient from "@/helpers/http-client";
import config from "@/config/config";
import { ForgotPassword } from "@/domain/forgot-password.model";

const getError = (error: any) => {
  const message = error?.response?.data?.error || error.message || "Failed";
  return new Error(message);
};

export default class AuthService {
  private static urlApiBase = config.url_api_base;

  static loginByAuth = async (username: string, password: string, tokenReCaptcha: string): Promise<LoginResponse> => {
    return httpClient
      .request<LoginResponse>({
        url: `${this.urlApiBase}/auth/login-admin`,
        method: "POST",
        body: {
          username,
          password,
          tokenReCaptcha,
        },
      })
      .then(result => result?.body);
  };

  static getProfile = async () => {
    try {
      const user: IUser = JSON.parse(localStorage.getItem("user"));

      return user;
    } catch (error) {
      throw getError(error);
    }
  };

  static forgotPassword = async (email: string, tokenReCaptcha: string): Promise<ForgotPassword> => {
    return httpClient
      .request<ForgotPassword>({
        url: `${this.urlApiBase}/auth/forgot-password`,
        method: "POST",
        body: {
          email,
          tokenReCaptcha,
        },
      })
      .then(result => result?.body);
  };
}
