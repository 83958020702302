import Toggle from "@vueform/toggle";
import { Money } from "v-money";
import { Options, Vue } from "vue-class-component";

import { IList } from "@/domain/list.model";
import { ProductCategory } from "@/domain/product-category.model";
import { ProductType } from "@/domain/product-type.model";
import { ErrorType } from "@/interfaces/error";
import i18n from "@/translation/i18n";
import { reactive } from "vue";

const { t } = i18n.global;

@Options({
  name: "Producr-new",
  components: {
    Toggle,
    Money,
  },
})
export default class ProductNew extends Vue {
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public categorys: IList[] = reactive([]);
  public types: IList[] = reactive([]);
  public money = {
    decimal: ".",
    thousands: "",
    precision: 2,
    masked: false,
  };

  public row = {
    id_category: 0,
    type: 0,
    price: null,
    discount: null,
    description: null,
    image: null,
    is_highlight: false,
    image_highlight: null,
    platform_video_name: null,
    video_display_name: null,
    enabled: true,
    isFree: false,
  };

  public created(): void {
    this.$store.dispatch("productCategory/listActive").then((res: ProductCategory[]) => {
      this.categorys = res.map(i => {
        return { id: i.id, description: i.description };
      });
    });
    this.$store.dispatch("productType/listActive").then((res: ProductType[]) => {
      this.types = res.map(i => {
        return { id: i.id, description: i.description };
      });
    });
  }

  public goBack() {
    this.$router.push("/product");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.id_category) {
      this.errors.id_category = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.type) {
      this.errors.type = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = t("labels.errors");
      this.hasError = true;
    }

    if (this.row.price && this.row.discount) {
      const price = parseFloat(this.row.price);
      const discount = parseFloat(this.row.discount);

      if (discount > price) {
        this.errors.discount = t("labels.errosDiscount");
        this.hasError = true;
      }
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("product/insert", {
          id_category: this.row.id_category,
          type: this.row.type,
          price: parseFloat(this.row.price),
          discount: parseFloat(this.row.discount),
          description: this.row.description,
          image: this.row.image,
          is_highlight: this.row.is_highlight,
          image_highlight: this.row.image_highlight,
          platform_video_name: this.row.platform_video_name,
          video_display_name: this.row.video_display_name,
          enabled: this.row.enabled,
          isFree: this.row.isFree,
        })
        .then(() => {
          this.$router.push("/product");
        })
        .catch(() => {
          this.errorMessage = t("labels.errorMessage");
        });
    }
  }
}
