import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { Faq } from "@/domain/faq.model";
import { ErrorType } from "@/interfaces/error";

@Options({
  name: "faq-edit",
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class FaqEdit extends Vue {
  public id: string;
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row: Faq = {
    question: "",
    answer: "",
    enabled: false,
  };

  public mounted(): void {
    this.$store
      .dispatch("faq/get", this.id)
      .then((res: Faq) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }

  public goBack() {
    this.$router.push("/faq");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.question) {
      this.errors.question = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("faq/update", {
          id: parseInt(this.id),
          question: this.row.question,
          answer: this.row.answer,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/faq");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
