import { reactive } from "vue";
import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import { Newsletter } from "@/domain/newsletter.model";

const { t } = i18n.global;

@Options({
  name: "newsletter-list",
  components: {
    TableLite,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.newsletter.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.newsletter.newsletters?.total;
    },
    newsletters(): Newsletter[] {
      return this.$store.state.newsletter.newsletters?.data;
    },
  },
  watch: {
    newsletters: function () {
      this.table.rows = this.newsletters;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class newsletterList extends PageBaseList {
  public table = TABLE;

  public mounted(): void {
    this.url = "newsletter";
    this.module = config.modules.newsletter;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public computed(): void {
    this.url = "newsletter";
    this.module = config.modules.newsletter;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: string) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    this.$store
      .dispatch("newsletter/list", { offset, limit, order, sort, filter })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public excluir(id: string | number) {
    this.$store
      .dispatch("newsletter/delete", id)
      .then(() => {
        this.table.isLoading;
      })
      .catch(() => {
        this.errorMessage = "Ocorreu um erro ao excluir.";
      });
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.id"),
      field: "id",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.description"),
      field: "description",
      sortable: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.active"),
      field: "enabled",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      field: "actions",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "id",
    sort: "asc",
  },
});
