export default {
  setSystemParams: (state, systemParams) => {
    state.systemParams = systemParams;
  },
  setSystemParam: (state, systemParam) => {
    state.systemParam = systemParam;
  },
  setSystemParamIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setSystemParamIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setSystemParamIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setSystemParamIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
