import { Users } from "@/domain/users.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  userses: (state): IPaginateVueTable<Users> => state.userses,
  users: (state): Users => state.users,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
