import { ProductLanguage } from "@/domain/product-language.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  productLanguages: (state): IPaginateVueTable<ProductLanguage> => state.productLanguages,
  productLanguage: (state): ProductLanguage => state.productLanguage,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
