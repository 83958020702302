import { Options, Vue } from "vue-class-component";

import List from "@/pages/config/system-param/list/list.vue";

@Options({
  name: "system-param",
  components: {
    "page-list": List,
  },
})
export default class SystemParam extends Vue {
  public isIndexOnly() {
    return /^[/]?system-param[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
