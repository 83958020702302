import { PaymentType } from "@/domain/payment-type.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import PaymentTypeService from "@/services/payment-type.service";

export default {
  async list({ commit }, query = null) {
    commit("setPaymentTypeIsLoading", true);

    return PaymentTypeService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<PaymentType>) => {
        commit("setPaymentTypes", res);
        commit("setPaymentTypeIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentTypeIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setPaymentTypeIsLoading", true);

    return PaymentTypeService.get(id)
      .then((res: PaymentType) => {
        commit("setPaymentType", res);
        commit("setPaymentTypeIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentTypeIsLoading", false);
      });
  },

  async insert({ commit }, PaymentType) {
    commit("setPaymentTypeIsCreating", true);

    return PaymentTypeService.insert({
      description: PaymentType.description,
      enabled: PaymentType.enabled,
    })
      .then(() => {
        commit("setPaymentTypeIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentTypeIsCreating", false);
      });
  },

  async update({ commit }, PaymentType) {
    commit("setPaymentTypeIsUpdating", true);

    return PaymentTypeService.update({
      id: PaymentType.id,
      description: PaymentType.description,
      enabled: PaymentType.enabled,
    })
      .then(() => {
        commit("setPaymentTypeIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setPaymentTypeIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setPaymentTypeIsLoading", true);

    return PaymentTypeService.delete(id)
      .then((res: PaymentType) => {
        commit("setPaymentTypeIsDeleting", res);
        commit("setPaymentTypeIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentTypeIsLoading", false);
      });
  },
};
