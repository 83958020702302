import { Payment } from "@/domain/payment.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  payments: (state): IPaginateVueTable<Payment> => state.payments,
  payment: (state): Payment => state.payment,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
