import { Options, Vue } from "vue-class-component";

import List from "@/pages/customer/list/list.vue";

@Options({
  name: "customer",
  components: {
    "page-list": List,
  },
})
export default class Customer extends Vue {
  public isIndexOnly() {
    return /^[/]?customer[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
