import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const paymentTypeModule = {
  namespaced: true,
  state: {
    paymentTypes: null,
    paymentType: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  mutations,
  actions,
  getters,
};

export default paymentTypeModule;
