export default {
  setOrders: (state, orders) => {
    state.orders = orders;
  },
  setOrder: (state, order) => {
    state.order = order;
  },
  setOrderIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setOrderIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setOrderIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setOrderIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
