export default {
  setUserses: (state, userses) => {
    state.userses = userses;
  },
  setUsers: (state, users) => {
    state.users = users;
  },
  setUsersIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setUsersIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setUsersIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setUsersIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
