import { ProductType } from "@/domain/product-type.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  productTypes: (state): IPaginateVueTable<ProductType> => state.productTypes,
  productType: (state): ProductType => state.productType,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
