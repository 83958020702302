import { Options, Vue } from "vue-class-component";

import List from "@/pages/config/payment-type/list/list.vue";

@Options({
  name: "payment-type",
  components: {
    "page-list": List,
  },
})
export default class PaymentType extends Vue {
  public isIndexOnly() {
    return /^[/]?payment-type[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
