import { LoginHistory } from "@/domain/login-history.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import LoginHistoryService from "@/services/login-history.service";

export default {
  async list({ commit }, query = null) {
    commit("setLoginHistoryIsLoading", true);

    return LoginHistoryService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<LoginHistory>) => {
        commit("setLoginHistorys", res);
        commit("setLoginHistoryIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setLoginHistoryIsLoading", false);
      });
  },

  async export({ commit }, filter = null) {
    commit("setReportsIsLoading", true);

    return LoginHistoryService.export(filter)
      .then((res: LoginHistory[]) => {
        commit("setLoginHistory", res);
        commit("setReportsIsLoading", false);

        return res?.map(i => ({
          ...i,
          type: i.type === 1 ? "Email" : "OAuth",
          origin:
            i.origin === 1
              ? "gmail"
              : i.origin === 2
              ? "facebook"
              : i.origin === 3
              ? "twitter"
              : i.origin === 4
              ? "instagram"
              : i.origin === 5
              ? "apple"
              : "-",
        }));
      })
      .catch(error => {
        console.log("error", error);
        commit("setReportsIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setLoginHistoryIsLoading", true);

    return LoginHistoryService.get(id)
      .then((res: LoginHistory) => {
        commit("setLoginHistory", res);
        commit("setLoginHistoryIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setLoginHistoryIsLoading", false);
      });
  },
};
