import { Product } from "@/domain/product.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import ProductService from "@/services/product.service";

export default {
  async list({ commit }, query = null) {
    commit("setProductIsLoading", true);

    return ProductService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Product>) => {
        commit("setProducts", res);
        commit("setProductIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setProductIsLoading", true);

    return ProductService.get(id)
      .then((res: Product) => {
        commit("setProduct", res);
        commit("setProductIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductIsLoading", false);
      });
  },

  async listActive({ commit }) {
    commit("setProductIsLoading", true);

    return ProductService.listActive()
      .then((res: Product[]) => {
        commit("setProduct", res);
        commit("setProductIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsLoading", false);
      });
  },

  async insert({ commit }, product) {
    commit("setProductIsCreating", true);

    return ProductService.insert({
      id_category: product.id_category,
      type: product.type,
      price: product.price,
      discount: product.discount,
      description: product.description,
      image: product.image,
      is_highlight: product.is_highlight,
      image_highlight: product.image_highlight,
      platform_video_name: product.platform_video_name,
      video_display_name: product.video_display_name,
      enabled: product.enabled,
      isFree: product.isFree,
    })
      .then(() => {
        commit("setProductIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductIsCreating", false);
        throw error;
      });
  },

  async update({ commit }, product) {
    commit("setProductIsUpdating", true);

    return ProductService.update({
      id: product.id,
      id_category: product.id_category,
      type: product.type,
      price: product.price,
      discount: product.discount,
      description: product.description,
      image: product.image,
      is_highlight: product.is_highlight,
      image_highlight: product.image_highlight,
      platform_video_name: product.platform_video_name,
      video_display_name: product.video_display_name,
      enabled: product.enabled,
      isFree: product.isFree,
    })
      .then(() => {
        commit("setProductIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setProductIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setProductIsLoading", true);

    return ProductService.delete(id)
      .then((res: Product) => {
        commit("setProductIsDeleting", res);
        commit("setProductIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductIsLoading", false);
      });
  },
};
