import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const platformModule = {
  namespaced: true,
  state: {
    platforms: null,
    platform: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  mutations,
  actions,
  getters,
};

export default platformModule;
