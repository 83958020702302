import { LoginHistory } from "@/domain/login-history.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  loginHistorys: (state): IPaginateVueTable<LoginHistory> => state.loginHistorys,
  loginHistory: (state): LoginHistory => state.loginHistory,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
