import { useToast } from "vue-toastification";

import { Options, Vue } from "vue-class-component";
import { useReCaptcha } from "vue-recaptcha-v3";
import AuthService from "@/services/auth.service";

@Options({})
export default class ForgotPassword extends Vue {
  private appElement: HTMLElement | null = null;
  public sended: boolean = false;
  public email: string = "";
  private toast = useToast();
  private recaptcha = useReCaptcha();

  public mounted(): void {
    this.appElement = document.getElementById("app") as HTMLElement;
    this.appElement.classList.add("login-page");
  }

  public unmounted(): void {
    (this.appElement as HTMLElement).classList.remove("login-page");
  }

  public async getRecaptcha() {
    await this.recaptcha.recaptchaLoaded();

    return await this.recaptcha.executeRecaptcha("login");
  }

  public async forgotPassword(): Promise<void> {
    try {
      const tokenReCaptcha = await this.getRecaptcha();

      if (tokenReCaptcha) {
        AuthService.forgotPassword(this.email, tokenReCaptcha)
          .then(() => (this.sended = true))
          .catch(error => {
            this.toast.error(error.response?.data?.error);
          });
      }
    } catch (error) {
      const message = error?.response?.data?.message || error.message || "Failed";
      this.toast.error(message);
    }
  }
}
