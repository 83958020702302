/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useToast } from "vue-toastification";

import store from "@/store/index";
import { LoginModule } from "@/domain/login.model";

import Main from "@/modules/main/main.vue";
import Login from "@/modules/login/login.vue";
import Dashboard from "@/pages/dashboard/dashboard.vue";
import Profile from "@/pages/config/profile/profile.vue";
import ForgotPassword from "@/modules/forgot-password/forgot-password.vue";
import RecoverPassword from "@/modules/recover-password/recover-password.vue";
import PrivacyPolicy from "@/modules/privacy-policy/privacy-policy.vue";

import Users from "@/pages/config/users/routes";
import ProductCategory from "@/pages/product-category/routes";
import Customer from "@/pages/customer/routes";
import Faq from "@/pages/faq/routes";
import Newsletter from "@/pages/newsletter/routes";
import Order from "@/pages/order/routes";
import PaymentType from "@/pages/config/payment-type/routes";
import Platform from "@/pages/platform/routes";
import ProductType from "@/pages/config/product-type/routes";
import TermsUse from "@/pages/terms-use/routes";
import Payment from "@/pages/payment/routes";
import Product from "@/pages/product/routes";
import LoginHistory from "@/pages/login-history/routes";
import Reports from "@/pages/reports/routes";
import SystemParam from "@/pages/config/system-param/routes";

const toast = useToast();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      Users,
      ProductCategory,
      Customer,
      Faq,
      Newsletter,
      Order,
      PaymentType,
      Platform,
      ProductType,
      TermsUse,
      Payment,
      Product,
      LoginHistory,
      Reports,
      SystemParam,
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/recover-password",
    name: "RecoverPassword",
    component: RecoverPassword,
    props: route => ({ token: route.query.t }),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory("/#/"),
  routes,
});

router.beforeEach((to: any, from: any, next) => {
  const modules = store.getters["auth/modules"] || [];
  const expiresIn = store.getters["auth/expiresIn"] || 0;

  if (to.meta.path !== "/login" && to.meta.requiresAuth && expiresIn * 1000 < Date.now()) {
    store.dispatch("auth/logout");
  } else {
    if (to.meta.requiresAuth && !store.getters["auth/token"]) {
      next("/login");
    } else if (to.meta.requiresUnauth && !!store.getters["auth/token"]) {
      next("/");
    } else {
      let ok = true;

      if (to.meta.modulePermission) {
        const modulo = modules.find((i: LoginModule) => i.module === to.meta.modulePermission);

        if (!modulo || !to.meta.requiresPermission || !modulo[to.meta.requiresPermission]) {
          ok = false;
          toast.warning("Permissão negada.");
        }
      }

      if (ok) {
        next();
      }
    }
  }
});

export default router;
