import { Users } from "@/domain/users.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import UsersService from "@/services/users.service";

export default {
  async list({ commit }, query = null) {
    commit("setUsersIsLoading", true);

    return UsersService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Users>) => {
        commit("setUserses", res);
        commit("setUsersIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setUsersIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setUsersIsLoading", true);

    return UsersService.get(id)
      .then((res: Users) => {
        commit("setUsers", res);
        commit("setUsersIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setUsersIsLoading", false);
      });
  },

  async insert({ commit }, users) {
    commit("setUsersIsCreating", true);

    return UsersService.insert({
      id: users.id,
      idCustomer: users.idCustomer,
      name: users.name,
      email: users.email,
      username: users.username,
      password: users.password,
      type: users.type,
      enabled: users.enabled,
    })
      .then(() => {
        commit("setUsersIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setUsersIsCreating", false);
      });
  },

  async update({ commit }, users) {
    commit("setUsersIsUpdating", true);

    return UsersService.update({
      id: users.id,
      idCustomer: users.idCustomer,
      name: users.name,
      email: users.email,
      username: users.username,
      password: users.password,
      type: users.type,
      enabled: users.enabled,
    })
      .then(() => {
        commit("setUsersIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setUsersIsUpdating", false);
      });
  },

  async changePassword({ commit }, users) {
    commit("setUsersIsUpdating", true);

    return UsersService.changePassword({
      id: users.id,
      password: users.password,
      confirmPassword: users.confirmPassword,
    })
      .then(() => {
        commit("setUsersIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setUsersIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setUsersIsLoading", true);

    return UsersService.delete(id)
      .then((res: Users) => {
        commit("setUsersIsDeleting", res);
        commit("setUsersIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setUsersIsLoading", false);
      });
  },
};
