import { Newsletter } from "@/domain/newsletter.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  newsletters: (state): IPaginateVueTable<Newsletter> => state.newsletters,
  newsletter: (state): Newsletter => state.newsletter,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
