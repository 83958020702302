import { Customer } from "@/domain/customer.model";
import { Product } from "@/domain/product.model";
import { UserReport } from "@/domain/users.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import ReportsService from "@/services/reports.service";

export default {
  async customerNewsletterList({ commit }, query = null) {
    commit("setReportsIsLoading", true);

    return ReportsService.getReportCustomerNewsletter(
      query?.offset || 1,
      query?.limit || 25,
      query?.order,
      query?.sort,
      query?.filter,
    )
      .then((res: IPaginateVueTable<Customer>) => {
        commit("setReportCustomerNewsletter", res);
        commit("setReportsIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setReportsIsLoading", false);
      });
  },

  async customerNewsletterExport({ commit }, filter = null) {
    commit("setReportsIsLoading", true);

    return ReportsService.exportReportCustomerNewsletter(filter)
      .then((res: Customer[]) => {
        commit("setReportCustomerNewsletterExport", res);
        commit("setReportsIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setReportsIsLoading", false);
      });
  },

  async productList({ commit }, query = null) {
    commit("setReportsIsLoading", true);

    return ReportsService.getReportProduct(
      query?.offset || 1,
      query?.limit || 25,
      query?.order,
      query?.sort,
      query?.filter,
    )
      .then((res: IPaginateVueTable<Product>) => {
        commit("setReportProduct", res);
        commit("setReportsIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setReportsIsLoading", false);
      });
  },

  async productExport({ commit }, filter = null) {
    commit("setReportsIsLoading", true);

    return ReportsService.exportReportProduct(filter)
      .then((res: Product[]) => {
        commit("setReportProductExport", res);
        commit("setReportsIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setReportsIsLoading", false);
      });
  },

  async userRegisterList({ commit }, query = null) {
    commit("setReportsIsLoading", true);

    return ReportsService.getReportUserRegister(
      query?.offset || 1,
      query?.limit || 25,
      query?.order,
      query?.sort,
      query?.filter,
    )
      .then((res: IPaginateVueTable<UserReport>) => {
        commit("setReportUserRegister", res);
        commit("setReportsIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setReportsIsLoading", false);
      });
  },

  async userRegisterExport({ commit }, filter = null) {
    commit("setReportsIsLoading", true);

    return ReportsService.exportReportUserRegister(filter)
      .then((res: UserReport[]) => {
        commit("setReportUserRegisterExport", res);
        commit("setReportsIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setReportsIsLoading", false);
      });
  },
};
