import { Platform } from "@/domain/platform.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import PlatformService from "@/services/platform.service";

export default {
  async list({ commit }, query = null) {
    commit("setPlatformIsLoading", true);

    return PlatformService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Platform>) => {
        commit("setPlatforms", res);
        commit("setPlatformIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPlatformIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setPlatformIsLoading", true);

    return PlatformService.get(id)
      .then((res: Platform) => {
        commit("setPlatform", res);
        commit("setPlatformIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPlatformIsLoading", false);
      });
  },

  async insert({ commit }, platform) {
    commit("setPlatformIsCreating", true);

    return PlatformService.insert({
      id_product: platform.id_product,
      name: platform.name,
      code_video_external: platform.code_video_external,
      url: platform.url,
      enabled: platform.enabled,
    })
      .then(() => {
        commit("setPlatformIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setPlatformIsCreating", false);
      });
  },

  async update({ commit }, platform) {
    commit("setPlatformIsUpdating", true);

    return PlatformService.update({
      id: platform.id,
      id_product: platform.id_product,
      name: platform.name,
      code_video_external: platform.code_video_external,
      url: platform.url,
      enabled: platform.enabled,
    })
      .then(() => {
        commit("setPlatformIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setPlatformIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setPlatformIsLoading", true);

    return PlatformService.delete(id)
      .then((res: Platform) => {
        commit("setPlatformIsDeleting", res);
        commit("setPlatformIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPlatformIsLoading", false);
      });
  },
};
