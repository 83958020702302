import { Order } from "@/domain/order.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import OrderService from "@/services/order.service";

export default {
  async list({ commit }, query = null) {
    commit("setOrderIsLoading", true);

    return OrderService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Order>) => {
        commit("setOrders", res);
        commit("setOrderIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setOrderIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setOrderIsLoading", true);

    return OrderService.get(id)
      .then((res: Order) => {
        commit("setOrder", res);
        commit("setOrderIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setOrderIsLoading", false);
      });
  },

  async cancelOrder({ commit }, id) {
    commit("setOrderIsLoading", true);

    return OrderService.cancelOrder(id)
      .then((res: Order) => {
        commit("setOrder", res);
        commit("setOrderIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setOrderIsLoading", false);
      });
  },

  async delete({ commit }, id) {
    commit("setOrderIsLoading", true);

    return OrderService.delete(id)
      .then((res: Order) => {
        commit("setOrderIsDeleting", res);
        commit("setOrderIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setOrderIsLoading", false);
      });
  },
};
