import { reactive } from "vue";
import { Options } from "vue-class-component";
import { parse, format } from "date-fns";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import { Payment } from "@/domain/payment.model";
import { UtilDate } from "@/helpers/date";

const { t } = i18n.global;

@Options({
  name: "payment-list",
  components: {
    TableLite,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.payment.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.payment.payments?.total;
    },
    payments(): Payment[] {
      return this.$store.state.payment.payments?.data;
    },
  },
  watch: {
    payments: function () {
      this.table.rows = this.payments;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class PaymenptList extends PageBaseList {
  public table = TABLE;
  public filterPayment = {
    name: null,
    type: null,
    dateIni: null,
    dateEnd: null,
    transaction_code: null,
  };

  public mounted(): void {
    this.url = "payment";
    this.module = config.modules.productCategory;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "transaction_date", "desc");
  }

  public computed(): void {
    this.url = "payment";
    this.module = config.modules.payment;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "transaction_date", "desc");
  }

  public doFilter() {
    this.doSearch(0, this.per_page, "transaction_date", "desc", this.filterPayment);
  }

  public formatDate(date: string | Date): string {
    return date ? UtilDate.format(date, "DD/MM/YYYY HH:mm") : "";
  }

  public formatNumber(value) {
    return value ? Number(value).toFixed(2) : "-";
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: Record<string, unknown>) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    const filterWithFormattedDates = {
      ...filter,
      dateIni: this.filterPayment.dateIni
        ? format(parse(this.filterPayment.dateIni, "dd/MM/yyyy", new Date()), "yyyy/MM/dd")
        : null,
      dateEnd: this.filterPayment.dateEnd
        ? format(parse(this.filterPayment.dateEnd, "dd/MM/yyyy", new Date()), "yyyy/MM/dd")
        : null,
    };

    this.$store
      .dispatch("payment/list", { offset, limit, order, sort, filter: filterWithFormattedDates })
      .then(response => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
        this.table.rows = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public excluir(id: string | number) {
    this.$store
      .dispatch("payment/delete", id)
      .then(() => {
        this.table.isLoading;
      })
      .catch(() => {
        this.errorMessage = "Ocorreu um erro ao excluir.";
      });
  }

  public clear() {
    this.filterPayment = {
      name: null,
      type: null,
      dateIni: null,
      dateEnd: null,
      transaction_code: null,
    };
    this.doSearch(0, this.per_page, "transaction_date", "desc");
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.id"),
      field: "id",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.customer"),
      field: "name",
      sortable: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.amount"),
      field: "amount",
      sortable: true,
      columnStyles: "text-align: right",
    },
    {
      label: t("labels.entity.type"),
      field: "type",
      sortable: true,
    },
    {
      label: t("labels.entity.transactionCode"),
      field: "transaction_code",
      sortable: true,
    },
    {
      label: t("labels.entity.transactionDate"),
      field: "transaction_date",
      sortable: true,
    },
    {
      field: "actions",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "transaction_date",
    sort: "desc",
  },
});
