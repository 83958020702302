export default {
  setCustomers: (state, customers) => {
    state.customers = customers;
  },
  setCustomer: (state, customer) => {
    state.customer = customer;
  },
  setCustomerIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setCustomerIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setCustomerIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setCustomerIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
