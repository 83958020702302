export default {
  setPlatforms: (state, platforms) => {
    state.platforms = platforms;
  },
  setPlatform: (state, platform) => {
    state.platform = platform;
  },
  setPlatformIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setPlatformIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setPlatformIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setPlatformIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
