export default {
  setProductLanguages: (state, productLanguages) => {
    state.productLanguages = productLanguages;
  },
  setProductLanguage: (state, productLanguage) => {
    state.productLanguage = productLanguage;
  },
  setProductLanguageIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setProductLanguageIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setProductLanguageIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setProductLanguageIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
