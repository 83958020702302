import { createApp } from "vue";
import App from "./app/app.vue";
import router from "./router";
import store from "./store";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGooglePlus } from "@fortawesome/free-brands-svg-icons";
import Toast, { PluginOptions } from "vue-toastification";
import { vMaska } from "maska";
import money from "v-money";
import { VueReCaptcha } from "vue-recaptcha-v3";
import excel from "vue-json-excel3";

import i18n from "./translation/i18n";
import "./index.scss";
import config from "./config/config";

library.add(faLock, faEnvelope, faFacebook, faGooglePlus);

const options: PluginOptions = {
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("downloadExcel", excel)
  .use(store)
  .use(router)
  .use(Toast, options)
  .use(i18n)
  .directive("maska", vMaska)
  .use(money, { precision: 2 })
  .use(VueReCaptcha, { siteKey: config.reCaptchaKey, loaderOptions: {} })
  .mount("#app");
