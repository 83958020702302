export default {
  setFaqs: (state, faqs) => {
    state.faqs = faqs;
  },
  setFaq: (state, faq) => {
    state.faq = faq;
  },
  setFaqIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setFaqIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setFaqIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setFaqIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
