import { Options, Vue } from "vue-class-component";

@Options({
  name: "logout",
})
export default class Logout extends Vue {
  private logout() {
    this.$store.dispatch("auth/logout");
  }
}
