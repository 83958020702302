import { Options, Vue } from "vue-class-component";

import List from "@/pages/login-history/list/list.vue";

@Options({
  name: "login-history",
  components: {
    "page-list": List,
  },
})
export default class loginHistory extends Vue {
  public isIndexOnly() {
    return /^[/]?login-history[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
