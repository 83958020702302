import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { ErrorType } from "@/interfaces/error";

@Options({
  name: "product-category-new",
  components: {
    Toggle,
  },
})
export default class ProductCategoryNew extends Vue {
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row = {
    description: "",
    enabled: true,
  };

  public goBack() {
    this.$router.push("/product-category");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    // this.row.city = this.row.city?.trim();

    if (!this.row.description) {
      this.errors.description = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("productCategory/insert", {
          description: this.row.description,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/product-category");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
