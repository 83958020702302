import config from "@/config/config";
import { headers } from "./auth.headers";
import { formatQueryString } from "@/helpers/query-string";
import { Order } from "@/domain/order.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import httpClient from "@/helpers/http-client";

export default class OrderService {
  private static urlApiBase = config.url_api_base;

  static insert = (data: Order): Promise<Order> => {
    return httpClient
      .request<Order>({
        url: `${this.urlApiBase}/order`,
        method: "POST",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static update = (data: Order): Promise<Order> => {
    return httpClient
      .request<Order>({
        url: `${this.urlApiBase}/order/${data.id}`,
        method: "PATCH",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static get = (id: string): Promise<Order> => {
    const url = `${this.urlApiBase}/order/${id}`;

    return httpClient
      .request<Order>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static list = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string,
  ): Promise<IPaginateVueTable<Order>> => {
    const query = formatQueryString(offset, limit, order, sort, filter, true);
    const url = `${this.urlApiBase}/order${query}`;

    return httpClient
      .request<IPaginateVueTable<Order>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static listActive = (): Promise<Order[]> => {
    const url = `${this.urlApiBase}/order/list-active`;

    return httpClient
      .request<Order[]>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static delete = (id: string): Promise<Order> => {
    const url = `${this.urlApiBase}/order/${id}`;

    return httpClient
      .request<Order>({
        url,
        method: "DELETE",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static cancelOrder = (id: string): Promise<Order> => {
    return httpClient
      .request<Order>({
        url: `${this.urlApiBase}/order/${id}/cancel-order`,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };
}
