import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { ErrorType } from "@/interfaces/error";
import { Faq } from "@/domain/faq.model";

@Options({
  name: "faq-new",
  components: {
    Toggle,
  },
})
export default class FaqNew extends Vue {
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row: Faq = {
    question: "",
    answer: "",
    enabled: true,
  };

  public goBack() {
    this.$router.push("/faq");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.question) {
      this.errors.question = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("faq/insert", {
          question: this.row.question,
          answer: this.row.answer,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/faq");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
