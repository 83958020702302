import config from "@/config/config";

import Index from "@/pages/config/product-type/index.vue";
import List from "@/pages/config/product-type/list/list.vue";
import View from "@/pages/config/product-type/view/view.vue";
import New from "@/pages/config/product-type/new/new.vue";
import Edit from "@/pages/config/product-type/edit/edit.vue";

const modulePermission = config.modules.productType;

export default {
  path: "/product-type",
  name: "product-type",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "list",
      name: "product-type-list",
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: ":id",
      name: "product-type-view",
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
      props: true,
    },
    {
      path: "new",
      name: "product-type-new",
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_create",
      },
    },
    {
      path: ":id/e",
      name: "product-type-edit",
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_edit",
      },
      props: true,
    },
  ],
};
