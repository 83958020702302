import config from "@/config/config";

import Index from "@/pages/newsletter/index.vue";
import List from "@/pages/newsletter/list/list.vue";
import View from "@/pages/newsletter/view/view.vue";
import New from "@/pages/newsletter/new/new.vue";
import Edit from "@/pages/newsletter/edit/edit.vue";

const modulePermission = config.modules.newsletter;

export default {
  path: "/newsletter",
  name: "newsletter",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "list",
      name: "newsletter-list",
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: ":id",
      name: "newsletter-view",
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
      props: true,
    },
    {
      path: "new",
      name: "newsletter-new",
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_create",
      },
    },
    {
      path: ":id/e",
      name: "newsletter-edit",
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_edit",
      },
      props: true,
    },
  ],
};
