import { Customer } from "@/domain/customer.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import CustomerService from "@/services/customer.service";

export default {
  async list({ commit }, query = null) {
    commit("setCustomerIsLoading", true);

    return CustomerService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Customer>) => {
        commit("setCustomers", res);
        commit("setCustomerIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setCustomerIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setCustomerIsLoading", true);

    return CustomerService.get(id)
      .then((res: Customer) => {
        commit("setCustomer", res);
        commit("setCustomerIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setCustomerIsLoading", false);
      });
  },

  async listActive({ commit }) {
    commit("setCustomerIsLoading", true);

    return CustomerService.listActive()
      .then((res: Customer[]) => {
        commit("setCustomer", res);
        commit("setCustomerIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsLoading", false);
      });
  },

  async insert({ commit }, customer) {
    commit("setCustomerIsCreating", true);

    return CustomerService.insert({
      name: customer.name,
      email: customer.email,
      accept_newsletter: customer.accept_newsletter,
      accept_terms_use: customer.accept_terms_use,
      enabled: customer.enabled,
    })
      .then(() => {
        commit("setCustomerIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setCustomerIsCreating", false);
      });
  },

  async update({ commit }, customer) {
    commit("setCustomerIsUpdating", true);

    return CustomerService.update({
      id: customer.id,
      name: customer.name,
      email: customer.email,
      accept_newsletter: customer.accept_newsletter,
      accept_terms_use: customer.accept_terms_use,
      enabled: customer.enabled,
    })
      .then(() => {
        commit("setCustomerIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setCustomerIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setCustomerIsLoading", true);

    return CustomerService.delete(id)
      .then((res: Customer) => {
        commit("setCustomerIsDeleting", res);
        commit("setCustomerIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setCustomerIsLoading", false);
      });
  },
};
