import { Customer } from "@/domain/customer.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  customers: (state): IPaginateVueTable<Customer> => state.customers,
  customer: (state): Customer => state.customer,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
