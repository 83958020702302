export default {
  setPayments: (state, payments) => {
    state.payments = payments;
  },
  setPayment: (state, payment) => {
    state.payment = payment;
  },
  setPaymentIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setPaymentIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setPaymentIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setPaymentIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
