export default {
  setReportsIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setReportCustomerNewsletter: (state, reports) => {
    state.reportCustomerNewsletter = reports;
  },
  setReportCustomerNewsletterExport: (state, reports) => {
    state.reportCustomerNewsletterExport = reports;
  },
  setReportProduct: (state, reports) => {
    state.reportProduct = reports;
  },
  setReportProductExport: (state, reports) => {
    state.reportProductExport = reports;
  },
  setReportUserRegister: (state, reports) => {
    state.reportUserRegister = reports;
  },
  setReportUserRegisterExport: (state, reports) => {
    state.reportUserRegisterExport = reports;
  },
};
