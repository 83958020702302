export default {
  setLoginHistorys: (state, loginHistorys) => {
    state.loginHistorys = loginHistorys;
  },
  setLoginHistory: (state, loginHistory) => {
    state.loginHistory = loginHistory;
  },
  setLoginHistoryIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setLoginHistoryIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setLoginHistoryIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setLoginHistoryIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
