import { LoginModule } from "@/domain/login.model";
import { IAuthState } from "@/interfaces/state";
import { IUser } from "@/interfaces/user";

export default {
  token: (state: IAuthState): string => state.token,
  expiresIn: (state: IAuthState): number => state.expiresIn,
  user: (state: IAuthState): IUser => state.user,
  modules: (state: IAuthState): LoginModule => state.modules,
};
