import * as SystemUser from "@/services/system-user.service";
import { Options, Vue } from "vue-class-component";
import Button from "@/components/button/button.vue";
import Input from "@/components/input/input.vue";
import { useToast } from "vue-toastification";

@Options({
  components: {
    "app-input": Input,
    "app-button": Button,
  },
  props: {
    token: String,
  },
})
export default class RecoverPassword extends Vue {
  private appElement: HTMLElement | null = null;
  public sended: boolean = false;
  public token: string = "";
  public password: string = "";
  public confirmPassword: string = "";
  private toast = useToast();
  public hasError = false;

  public mounted(): void {
    this.appElement = document.getElementById("app") as HTMLElement;
    this.appElement.classList.add("login-page");

    if (!this.token) {
      this.$router.push("/");
    }
  }

  public unmounted(): void {
    (this.appElement as HTMLElement).classList.remove("login-page");
  }

  public validation() {
    this.hasError = false;

    if (this.confirmPassword !== this.password) {
      this.toast.error("Senha e Confirmação de Senha não conferem.");
      this.hasError = true;
    }

    if (!this.password) {
      this.toast.error("Este campo deve ser preenchido.");
      this.hasError = true;
    }

    if (!this.confirmPassword) {
      this.toast.error("Este campo deve ser preenchido.");
      this.hasError = true;
    }

    if (this.password.length < 6) {
      this.toast.error("Senha deve ter pelo menos 6 caracteres.");
      this.hasError = true;
    }

    return !this.hasError;
  }

  public recoverPassword(): void {
    if (this.validation()) {
      SystemUser.recoverPassword({
        token: this.token,
        password: this.password,
        confirmPassword: this.confirmPassword,
      })
        .then(() => this.$router.push("/"))
        .catch(error => {
          this.toast.error(error.response?.data?.error);
        });
    }
  }
}
