import { Faq } from "@/domain/faq.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import FaqService from "@/services/faq.service";

export default {
  async list({ commit }, query = null) {
    commit("setFaqIsLoading", true);

    return FaqService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Faq>) => {
        commit("setFaqs", res);
        commit("setFaqIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setFaqIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setFaqIsLoading", true);

    return FaqService.get(id)
      .then((res: Faq) => {
        commit("setFaq", res);
        commit("setFaqIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setFaqIsLoading", false);
      });
  },

  async insert({ commit }, faq) {
    commit("setFaqIsCreating", true);

    return FaqService.insert({
      question: faq.question,
      answer: faq.answer,
      enabled: faq.enabled,
    })
      .then(() => {
        commit("setFaqIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setFaqIsCreating", false);
      });
  },

  async update({ commit }, faq) {
    commit("setFaqIsUpdating", true);

    return FaqService.update({
      id: faq.id,
      question: faq.question,
      answer: faq.answer,
      enabled: faq.enabled,
    })
      .then(() => {
        commit("setFaqIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setFaqIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setFaqIsLoading", true);

    return FaqService.delete(id)
      .then((res: Faq) => {
        commit("setFaqIsDeleting", res);
        commit("setFaqIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setFaqIsLoading", false);
      });
  },
};
