import { Options, Vue } from "vue-class-component";

import List from "@/pages/payment/list/list.vue";

@Options({
  name: "payment",
  components: {
    "page-list": List,
  },
})
export default class Paymenpt extends Vue {
  public isIndexOnly() {
    return /^[/]?payment[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
