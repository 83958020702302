import { UIStore } from "@/interfaces/state";

export default {
  darkModeSelected: (state: UIStore): boolean => state.darkMode,
  menuSidebarCollapsed: (state: UIStore): boolean => state.menuSidebarCollapsed,
  controlSidebarCollapsed: (state: UIStore): boolean => state.controlSidebarCollapsed,
  screenSize: (state: UIStore): string | number => state.screenSize,
  navbarVariant: (state: UIStore): string | number => state.navbarVariant,
  sidebarSkin: (state: UIStore): string | number => state.sidebarSkin,
};
