import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { TermsUse } from "@/domain/terms-use.model";
import { ErrorType } from "@/interfaces/error";

@Options({
  name: "terms-use-edit",
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class TermsUseEdit extends Vue {
  public id: string;
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row: TermsUse = {
    description: "",
    enabled: false,
  };

  public mounted(): void {
    this.$store
      .dispatch("termsUse/get", this.id)
      .then((res: TermsUse) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }

  public goBack() {
    this.$router.push("/terms-use");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.description) {
      this.errors.description = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("termsUse/update", {
          id: parseInt(this.id),
          description: this.row.description,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/terms-use");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
