import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { ErrorType } from "@/interfaces/error";
import { SystemParam } from "@/domain/system-param.model";
import i18n from "@/translation/i18n";

const { t } = i18n.global;

@Options({
  name: "system-param-edit",
  props: {
    param: String,
  },
  components: {
    Toggle,
  },
})
export default class SystemParamEdit extends Vue {
  public param: string;
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row: SystemParam = {
    param: "",
    value: "",
    description: "",
    enabled: false,
  };

  public mounted(): void {
    this.$store
      .dispatch("systemParam/get", this.param)
      .then((res: SystemParam) => {
        if (res && res.param) {
          console.log(this.param);
          this.row = res;
        } else {
          this.errorMessage = t("labels.noDataAvailable");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.noDataAvailable");
      });
  }

  public goBack() {
    this.$router.push("/system-param");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.param) {
      this.errors.param = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.value) {
      this.errors.value = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = t("labels.errors");
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("systemParam/update", {
          param: (this.param),
          value: this.row.value,
          description: this.row.description,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/system-param");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
