import { ProductCategory } from "@/domain/product-category.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import ProductCategoryService from "@/services/product-category.service";

export default {
  async list({ commit }, query = null) {
    commit("setProductCategoryIsLoading", true);

    return ProductCategoryService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<ProductCategory>) => {
        commit("setProductCategorys", res);
        commit("setProductCategoryIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setProductCategoryIsLoading", true);

    return ProductCategoryService.get(id)
      .then((res: ProductCategory) => {
        commit("setProductCategory", res);
        commit("setProductCategoryIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsLoading", false);
      });
  },

  async listActive({ commit }) {
    commit("setProductCategoryIsLoading", true);

    return ProductCategoryService.listActive()
      .then((res: ProductCategory[]) => {
        commit("setProductCategory", res);
        commit("setProductCategoryIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsLoading", false);
      });
  },

  async insert({ commit }, productCategory) {
    commit("setProductCategoryIsCreating", true);

    return ProductCategoryService.insert({
      description: productCategory.description,
      enabled: productCategory.enabled,
    })
      .then(() => {
        commit("setProductCategoryIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsCreating", false);
      });
  },

  async update({ commit }, productCategory) {
    commit("setProductCategoryIsUpdating", true);

    return ProductCategoryService.update({
      id: productCategory.id,
      description: productCategory.description,
      enabled: productCategory.enabled,
    })
      .then(() => {
        commit("setProductCategoryIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setProductCategoryIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setProductCategoryIsLoading", true);

    return ProductCategoryService.delete(id)
      .then((res: ProductCategory) => {
        commit("setProductCategoryIsDeleting", res);
        commit("setProductCategoryIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsLoading", false);
      });
  },
};
