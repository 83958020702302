import config from "@/config/config";
import { headers } from "./auth.headers";
import { ProductLanguage } from "@/domain/product-language.model";
import httpClient from "@/helpers/http-client";

export default class ProductLanguageService {
  private static urlApiBase = config.url_api_base;

  static insert = (data: ProductLanguage): Promise<ProductLanguage> => {
    return httpClient
      .request<ProductLanguage>({
        url: `${this.urlApiBase}/product-language`,
        method: "POST",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static update = (data: ProductLanguage): Promise<ProductLanguage> => {
    return httpClient
      .request<ProductLanguage>({
        url: `${this.urlApiBase}/product-language/${data.id}`,
        method: "PATCH",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static get = (id: string): Promise<ProductLanguage> => {
    const url = `${this.urlApiBase}/product-language/${id}`;

    return httpClient
      .request<ProductLanguage>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static delete = (id: string): Promise<ProductLanguage> => {
    const url = `${this.urlApiBase}/product-language/${id}`;

    return httpClient
      .request<ProductLanguage>({
        url,
        method: "DELETE",
        headers: headers(),
      })
      .then(result => result?.body);
  };
}
