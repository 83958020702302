import config from "@/config/config";
import { headers } from "./auth.headers";
import { formatQueryString } from "@/helpers/query-string";
import { SystemParam } from "@/domain/system-param.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import httpClient from "@/helpers/http-client";

export default class SystemParamService {
  private static urlApiBase = config.url_api_base;

  static insert = (data: SystemParam): Promise<SystemParam> => {
    return httpClient
      .request<SystemParam>({
        url: `${this.urlApiBase}/system-param`,
        method: "POST",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static update = (data: SystemParam): Promise<SystemParam> => {
    return httpClient
      .request<SystemParam>({
        url: `${this.urlApiBase}/system-param/${data.param}`,
        method: "PATCH",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static get = (param: string): Promise<SystemParam> => {
    const url = `${this.urlApiBase}/system-param/${param}`;

    return httpClient
      .request<SystemParam>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static list = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string,
  ): Promise<IPaginateVueTable<SystemParam>> => {
    const query = formatQueryString(offset, limit, order, sort, filter, true);
    const url = `${this.urlApiBase}/system-param${query}`;

    return httpClient
      .request<IPaginateVueTable<SystemParam>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static delete = (param: string): Promise<SystemParam> => {
    const url = `${this.urlApiBase}/system-param/${param}`;

    return httpClient
      .request<SystemParam>({
        url,
        method: "DELETE",
        headers: headers(),
      })
      .then(result => result?.body);
  };
}
