import { Options, Vue } from "vue-class-component";

import List from "@/pages/faq/list/list.vue";

@Options({
  name: "faq",
  components: {
    "page-list": List,
  },
})
export default class Faq extends Vue {
  public isIndexOnly() {
    return /^[/]?faq[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
