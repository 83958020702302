import { Options, Vue } from "vue-class-component";

import List from "@/pages/product/list/list.vue";

@Options({
  name: "product",
  components: {
    "page-list": List,
  },
})
export default class Product extends Vue {
  public isIndexOnly() {
    return /^[/]?product[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
