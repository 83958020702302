import { Newsletter } from "@/domain/newsletter.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import NewsletterService from "@/services/newsletter.service";

export default {
  async list({ commit }, query = null) {
    commit("setNewsletterIsLoading", true);

    return NewsletterService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Newsletter>) => {
        commit("setNewsletters", res);
        commit("setNewsletterIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setNewsletterIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setNewsletterIsLoading", true);

    return NewsletterService.get(id)
      .then((res: Newsletter) => {
        commit("setNewsletter", res);
        commit("setNewsletterIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setNewsletterIsLoading", false);
      });
  },

  async insert({ commit }, Newsletter) {
    commit("setNewsletterIsCreating", true);

    return NewsletterService.insert({
      description: Newsletter.description,
      enabled: Newsletter.enabled,
    })
      .then(() => {
        commit("setNewsletterIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setNewsletterIsCreating", false);
      });
  },

  async update({ commit }, Newsletter) {
    commit("setNewsletterIsUpdating", true);

    return NewsletterService.update({
      id: Newsletter.id,
      description: Newsletter.description,
      enabled: Newsletter.enabled,
    })
      .then(() => {
        commit("setNewsletterIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setNewsletterIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setNewsletterIsLoading", true);

    return NewsletterService.delete(id)
      .then((res: Newsletter) => {
        commit("setNewsletterIsDeleting", res);
        commit("setNewsletterIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setNewsletterIsLoading", false);
      });
  },
};
