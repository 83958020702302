import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";
import i18n from "@/translation/i18n";

import { Users } from "@/domain/users.model";
import { ErrorType } from "@/interfaces/error";
import { Customer } from "@/domain/customer.model";
import { reactive } from "vue";
import { IList } from "@/domain/list.model";
const { t } = i18n.global;

@Options({
  name: "users-edit",
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class UsersEdit extends Vue {
  public id: string;
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public customers: IList[] = reactive([]);
  public row: Users = {
    idCustomer: 0,
    name: "",
    email: "",
    username: "",
    password: "",
    type: 0,
    enabled: false,
  };
  public types = [
    { id: 1, description: t('labels.user.type.admin') },
    { id: 2, description: t('labels.user.type.customer') },
  ]


  public created(): void {
    this.$store.dispatch("customer/listActive").then((res: Customer[]) => {
      this.customers = res.map(i => {
        return { id: i.id, description: i.name };
      });
    });
  }

  public mounted(): void {
    this.$store
      .dispatch("users/get", this.id)
      .then((res: Users) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = t("labels.noDataAvailable");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.noDataAvailable");
      });
  }

  public goBack() {
    this.$router.push("/users");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = t("labels.errors");
      this.hasError = true;
    } 

    if (!this.row.username) {
      this.errors.username = t("labels.errors");
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("users/update", {
          id: this.id,
          idCustomer: this.row.idCustomer,
          name: this.row.name,
          email: this.row.email,
          username: this.row.username,
          password: this.row.password,
          type: this.row.type,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/users");
        })
        .catch(() => {
          this.errorMessage = t("labels.errorMessage");
        });
    }
  }
}
