import { PaymentAttempt } from "@/domain/payment-attempt.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  paymentAttempts: (state): IPaginateVueTable<PaymentAttempt> => state.paymentAttempts,
  paymentAttempt: (state): PaymentAttempt => state.paymentAttempt,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
