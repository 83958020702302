import { SystemParam } from "@/domain/system-param.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import SystemParamService from "@/services/system-param.service";

export default {
  async list({ commit }, query = null) {
    commit("setSystemParamIsLoading", true);

    return SystemParamService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<SystemParam>) => {
        commit("setSystemParams", res);
        commit("setSystemParamIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setSystemParamIsLoading", false);
      });
  },

  async get({ commit }, param) {
    commit("setSystemParamIsLoading", true);

    return SystemParamService.get(param)
      .then((res: SystemParam) => {
        commit("setSystemParam", res);
        commit("setSystemParamIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setSystemParamIsLoading", false);
      });
  },

  async insert({ commit }, systemParam) {
    commit("setSystemParamIsCreating", true);

    return SystemParamService.insert({
      param: systemParam.param,
      value: systemParam.value,
      description: systemParam.description,
      enabled: systemParam.enabled,
    })
      .then(() => {
        commit("setSystemParamIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setSystemParamIsCreating", false);
      });
  },

  async update({ commit }, systemParam) {
    commit("setSystemParamIsUpdating", true);

    return SystemParamService.update({
      param: systemParam.param,
      value: systemParam.value,
      description: systemParam.description,
      enabled: systemParam.enabled,
    })
      .then(() => {
        commit("setSystemParamIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setSystemParamIsUpdating", false);
      });
  },

  async delete({ commit }, param) {
    commit("setSystemParamIsLoading", true);

    return SystemParamService.delete(param)
      .then((res: SystemParam) => {
        commit("setSystemParamIsDeleting", res);
        commit("setSystemParamIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setSystemParamIsLoading", false);
      });
  },
};
