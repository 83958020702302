import { Platform } from "@/domain/platform.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  platforms: (state): IPaginateVueTable<Platform> => state.platforms,
  platform: (state): Platform => state.platform,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
