import { reactive } from "vue";
import { Options } from "vue-class-component";

import TableLite from "@/components/table/TableLiteTs.vue";
import config from "@/config/config";
import { Product } from "@/domain/product.model";
import PageBaseList from "@/pages/PageBaseList";
import i18n from "@/translation/i18n";
import Toggle from "@vueform/toggle/src/Toggle";

const { t } = i18n.global;

@Options({
  name: "reports-product",
  components: {
    TableLite,
    Toggle,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.reports.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.reports.reportProduct?.total;
    },
    report(): Product[] {
      return this.$store.state.reports.reportProduct?.data;
    },
  },
  watch: {
    report: function () {
      this.table.rows = this.report;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class ReportProduct extends PageBaseList {
  public table = TABLE;
  public filterReports = {
    name: "",
    description: "",
    enabled: true,
  };

  public listaExcelFields = {
    id: "id",
    category: "category.description",
    type: "product_type.description",
    price: "price",
    discount: "discount",
    is_highlight: "is_highlight",
    video_display_name: "video_display_name",
    enabled: "enabled",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
  };

  public mounted(): void {
    this.module = config.modules.customer;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public computed(): void {
    this.module = config.modules.customer;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public doFilter() {
    this.doSearch(0, this.per_page, "id", "asc", this.filterReports);
  }

  public clear() {
    this.filterReports = {
      name: "",
      description: "",
      enabled: true,
    };

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public formatNumber(value) {
    return value ? Number(value).toFixed(2) : "-";
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: Record<string, unknown>) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    this.$store
      .dispatch("reports/productList", { offset, limit, order, sort, filter })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public gerarExcel() {
    return this.$store.dispatch("reports/productExport", this.filterReports);
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.id"),
      field: "id",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.category"),
      field: "category_description",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.type"),
      field: "type_description",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.price"),
      field: "price",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.discount"),
      field: "discount",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.is_highlight"),
      field: "is_highlight",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.totalCoursesPurchased"),
      field: "total_courses_purchased",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.video_display_name"),
      field: "video_display_name",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.active"),
      field: "enabled",
      sortable: true,
      columnStyles: "text-align: center",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "id",
    sort: "asc",
  },
});
