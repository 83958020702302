import { Options } from "vue-class-component";

import config from "@/config/config";
import PageBaseView from "@/pages/PageBaseView";
import { Payment } from "@/domain/payment.model";
import { UtilDate } from "@/helpers/date";
import { formatNumber } from "@/helpers/numbers";

@Options({
  name: "payment-view",
  props: {
    id: String,
  },
})
export default class PaymentyView extends PageBaseView {
  public row: Payment = {
    id_payment_attempt: 0,
    amount: 0,
    type: 0,
    transaction_code: "",
    transaction_date: new Date(),
  };

  public formatDate(date: string | Date): string {
    return date ? UtilDate.format(date, "DD/MM/YYYY") : "";
  }

  public formatNumber(value: number): string {
    return formatNumber(value);
  }

  public mounted(): void {
    this.url = "payment";
    this.module = config.modules.payment;

    this.$store
      .dispatch("payment/get", this.id)
      .then((res: Payment) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }
}
