import config from "@/config/config";
import { headers } from "./auth.headers";
import { formatQueryString } from "@/helpers/query-string";
import { Users } from "@/domain/users.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import { LoginRequest, LoginResponse } from "@/domain/login.model";
import { ChangePassword } from "@/domain/change-password.model";
import httpClient from "@/helpers/http-client";

const url_api_base = config.url_api_base;

export const insert = (data: Users): Promise<Users> => {
  return httpClient
    .request<Users>({
      url: `${url_api_base}/users`,
      method: "POST",
      body: data,
      headers: headers(),
    })
    .then(result => result?.body);
};

export const update = (data: Users): Promise<Users> => {
  return httpClient
    .request<Users>({
      url: `${url_api_base}/users/${data.id}`,
      method: "PATCH",
      body: data,
      headers: headers(),
    })
    .then(result => result?.body);
};

export const changePassword = (userId: number, password: string, confirmPassword: string): Promise<Users> => {
  const url = `${url_api_base}/users/${userId}/change-password`;
  const data = {
    password,
    confirmPassword,
  };

  return httpClient
    .request<Users>({
      url,
      method: "PATCH",
      body: data,
      headers: headers(),
    })
    .then(result => result?.body);
};

export const get = (id: string): Promise<Users> => {
  const url = `${url_api_base}/users/${id}`;

  return httpClient
    .request<Users>({
      url,
      method: "GET",
      headers: headers(),
    })
    .then(result => result?.body);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<Users>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/users${query}`;

  return httpClient
    .request<IPaginateVueTable<Users>>({
      url,
      method: "GET",
      headers: headers(),
    })
    .then(result => result?.body);
};

export const login = (data: LoginRequest): Promise<LoginResponse> => {
  return httpClient
    .request<LoginResponse>({
      url: `${url_api_base}/auth/login`,
      method: "POST",
      body: data,
      headers: headers(),
    })
    .then(result => result?.body);
};

export const forgotPassword = (username: string): Promise<Users> => {
  return httpClient
    .request<Users>({
      url: `${url_api_base}/auth/forgot-password/${username}`,
      method: "GET",
      headers: headers(),
    })
    .then(result => result?.body);
};

export const recoverPassword = (data: ChangePassword): Promise<ChangePassword> => {
  return httpClient
    .request<ChangePassword>({
      url: `${url_api_base}/auth/change-password`,
      method: "POST",
      body: data,
      headers: headers(),
    })
    .then(result => result?.body);
};
