import { reactive } from "vue";
import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import Toggle from "@vueform/toggle/src/Toggle";
import { Users } from "@/domain/users.model";
import { UtilDate } from "@/helpers/date";

const { t } = i18n.global;

@Options({
  name: "reports-user-register",
  components: {
    TableLite,
    Toggle,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.reports.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.reports.reportUserRegister?.total;
    },
    report(): Users {
      return this.$store.state.reports.reportUserRegister?.data;
    },
  },
  watch: {
    report: function () {
      this.table.rows = this.report;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class UserRegister extends PageBaseList {
  public table = TABLE;
  public filterReports = {
    name: "",
    email: "",
    dateIni: null,
    dateEnd: null,
    enabled: true,
  };

  public listaExcelFields = {
    name: "name",
    email: "email",
    totalCoursesPurchased: "total_courses_purchased",
    totalCoursesWatched: "total_courses_watched",
    enabled: "enabled",
    createdAt: "created_at",
  };

  public mounted(): void {
    this.module = config.modules.customer;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public computed(): void {
    this.module = config.modules.customer;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public doFilter() {
    this.doSearch(0, this.per_page, "id", "asc", this.filterReports);
  }

  public formatDate(date: string | Date): string {
    return date ? UtilDate.format(date, "DD/MM/YYYY") : "";
  }

  public clear() {
    this.filterReports = {
      name: "",
      email: "",
      dateIni: null,
      dateEnd: null,
      enabled: true,
    };

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: Record<string, unknown>) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.per_page) {
      limit = this.per_page;
    }

    this.$store
      .dispatch("reports/userRegisterList", { offset, limit, order, sort, filter })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public gerarExcel(filter?: Record<string, unknown>) {
    return this.$store.dispatch("reports/userRegisterExport", filter);
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.name"),
      field: "name",
      sortable: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.email"),
      field: "email",
      sortable: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.createdAt"),
      field: "created_at",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.active"),
      field: "enabled",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.totalCoursesPurchased"),
      field: "total_courses_purchased",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.totalCoursesWatched"),
      field: "total_courses_watched",
      sortable: true,
      columnStyles: "text-align: center",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "id",
    sort: "asc",
  },
});
