export default {
  setProducts: (state, products) => {
    state.products = products;
  },
  setProduct: (state, product) => {
    state.product = product;
  },
  setProductIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setProductIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setProductIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setProductIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
