import { Options, Vue } from "vue-class-component";

import List from "@/pages/order/list/list.vue";

@Options({
  name: "order",
  components: {
    "page-list": List,
  },
})
export default class Order extends Vue {
  public isIndexOnly() {
    return /^[/]?order[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
