import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseView from "@/pages/PageBaseView";
import { Users } from "@/domain/users.model";

const { t } = i18n.global;

@Options({
  name: "users-view",
  props: {
    id: String,
  },
})
export default class UsersView extends PageBaseView {
  public showModalChangePassword = false;
  public showPasswordField = false;
  public showConfirmPasswordField = false;
  public row: Users = {
    idCustomer: 0,
    name: "",
    email: "",
    username: "",
    password: "",
    type: 0,
    enabled: false,
  };
  public rowPassword = {
    password: "",
    confirmPassword: "", 
  }

  public mounted(): void {
    this.url = "users";
    this.module = config.modules.productType;

    this.$store
      .dispatch("users/get", this.id)
      .then((res: Users) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = t("labels.noDataAvailable");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.noDataAvailable");
      });
  }

  public togglePasswordVisibility() {
    this.showPasswordField = !this.showPasswordField;
  }

  public toggleConfirmPasswordVisibility() {
    this.showConfirmPasswordField = !this.showConfirmPasswordField;
  }

  public newPassword(): void {
    this.rowPassword = {
      password: "",
      confirmPassword: "", 
    };
    this.showModalChangePassword = true;
  }

  public validationPassword(newRow = true) {
    this.errorMessage = '';
    this.errors = {};
    this.hasError = false;
  
    if (!this.rowPassword.password) {
      this.errors.password = t("labels.errors");
      this.hasError = true;
    }
  
    if (!this.rowPassword.confirmPassword) {
      this.errors.confirmPassword = t("labels.errors");
      this.hasError = true;
    }
  
    if (this.rowPassword.password !== this.rowPassword.confirmPassword) {
      this.errors.confirmPassword = t("labels.errorsConfirmPassword");
      this.hasError = true;
    }

    return !this.hasError;
  }
  
  public savePassword(newRow = true): void {
    if (this.validationPassword(newRow)) {
      this.$store
      .dispatch("users/changePassword", {
        id: this.id,
        password: this.rowPassword.password,
        confirmPassword: this.rowPassword.confirmPassword,
      })
      .then(() => {
        this.closeModal();
      })
      .catch(() => {
        this.errorMessage = t("labels.errorMessage");
      });
    }
  }

  public closeModal(): void {
    this.showModalChangePassword = false;
  }
}
