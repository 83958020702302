
import { defineComponent, ref, reactive, computed, watch, onBeforeUpdate, nextTick, onMounted } from "vue";
interface pageOption {
  value: number;
  text: number | string;
}
interface tableSetting {
  isSlotMode: boolean;
  isCheckAll: boolean;
  isHidePaging: boolean;
  keyColumn: string;
  page: number;
  pageSize: number;
  maxPage: number;
  offset: number;
  limit: number;
  paging: Array<number>;
  order: string;
  sort: string;
  pageOptions: Array<pageOption>;
}
interface column {
  isKey: string;
  field: string;
}
export default defineComponent({
  name: "my-table",
  emits: ["return-checked-rows", "do-search", "is-finished", "get-now-page"],
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
    isReSearch: {
      type: Boolean,
      require: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: (): any[] => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: (): any[] => {
        return [];
      },
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    total: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
        };
      },
    },
    isStaticMode: {
      type: Boolean,
      default: false,
    },
    isSlotMode: {
      type: Boolean,
      default: false,
    },
    isHidePaging: {
      type: Boolean,
      default: false,
    },
    pageOptions: {
      type: Array,
      default: () => [
        {
          value: 10,
          text: 10,
        },
        {
          value: 25,
          text: 25,
        },
        {
          value: 50,
          text: 50,
        },
        {
          value: 100,
          text: 100,
        },
      ],
    },
  },
  setup(props, { emit, slots }) {
    const localTable = ref<HTMLElement | null>(null);
    const tmpPageOptions = props.pageOptions as Array<pageOption>;
    const defaultPageSize = props.pageOptions.length > 0 ? ref(tmpPageOptions[0].value) : ref(props.pageSize);
    if (tmpPageOptions.length > 0) {
      tmpPageOptions.forEach((v: pageOption) => {
        if (
          Object.prototype.hasOwnProperty.call(v, "value") &&
          Object.prototype.hasOwnProperty.call(v, "text") &&
          props.pageSize == v.value
        ) {
          defaultPageSize.value = v.value;
        }
      });
    }
    const setting: tableSetting = reactive({
      isSlotMode: props.isSlotMode,
      isCheckAll: false,
      isHidePaging: props.isHidePaging,
      keyColumn: computed(() => {
        let key = "";
        Object.assign(props.columns).forEach((col: column) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      page: props.page,
      pageSize: defaultPageSize.value,
      maxPage: computed(() => {
        if (props.total <= 0) {
          return 0;
        }
        let maxPage = Math.floor(props.total / setting.pageSize);
        const mod = props.total % setting.pageSize;
        if (mod > 0) {
          maxPage++;
        }
        return maxPage;
      }),
      offset: computed(() => {
        return (setting.page - 1) * setting.pageSize + 1;
      }),
      limit: computed(() => {
        const limit = setting.page * setting.pageSize;
        return props.total >= limit ? limit : props.total;
      }),
      paging: computed(() => {
        let startPage = setting.page - 2 <= 0 ? 1 : setting.page - 2;
        if (setting.maxPage - setting.page <= 2) {
          startPage = setting.maxPage - 4;
        }
        startPage = startPage <= 0 ? 1 : startPage;
        const pages = [];
        for (let i = startPage; i <= setting.maxPage; i++) {
          if (pages.length < 5) {
            pages.push(i);
          }
        }
        return pages;
      }),
      order: props.sortable.order,
      sort: props.sortable.sort,
      pageOptions: computed(() => {
        const ops: pageOption[] = [];
        props.pageOptions?.forEach(o => {
          ops.push({
            value: (o as pageOption).value,
            text: (o as pageOption).text,
          });
        });
        return ops;
      }),
    });
    const localRows = computed(() => {
      const property = setting.order;
      let sort_order = 1;
      if (setting.sort === "desc") {
        sort_order = -1;
      }
      const rows = props.rows as Array<unknown>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rows.sort((a: any, b: any): number => {
        if (a[property] < b[property]) {
          return -1 * sort_order;
        } else if (a[property] > b[property]) {
          return sort_order;
        } else {
          return 0;
        }
      });
      const result = [];
      for (let index = setting.offset - 1; index < setting.limit; index++) {
        if (rows[index]) {
          result.push(rows[index]);
        }
      }
      nextTick(function () {
        callIsFinished();
      });
      return result;
    });

    const rowCheckbox = ref([]);
    if (props.hasCheckbox) {
      onBeforeUpdate(() => {
        rowCheckbox.value = [];
      });
      watch(
        () => setting.isCheckAll,
        (state: boolean) => {
          const isChecked: Array<string> = [];
          rowCheckbox.value.forEach((val: HTMLInputElement) => {
            if (val) {
              val.checked = state;
              if (val.checked) {
                isChecked.push(val.value);
              }
            }
          });
          emit("return-checked-rows", isChecked);
        },
      );
    }
    const checked = () => {
      const isChecked: Array<string> = [];
      rowCheckbox.value.forEach((val: HTMLInputElement) => {
        if (val && val.checked) {
          isChecked.push(val.value);
        }
      });
      emit("return-checked-rows", isChecked);
    };
    const clearChecked = () => {
      rowCheckbox.value.forEach((val: HTMLInputElement) => {
        if (val && val.checked) {
          val.checked = false;
        }
      });
      emit("return-checked-rows", []);
    };
    const doSort = (order: string) => {
      let sort = "asc";
      if (order == setting.order) {
        if (setting.sort == "asc") {
          sort = "desc";
        }
      }

      const offset = (setting.page - 1) * setting.pageSize;
      const limit = setting.pageSize;
      setting.order = order;
      setting.sort = sort;

      emit("do-search", offset, limit, order, sort);

      if (setting.isCheckAll) {
        setting.isCheckAll = false;
      } else {
        if (props.hasCheckbox) {
          clearChecked();
        }
      }
    };

    const changePage = (page: number, prevPage: number) => {
      setting.isCheckAll = false;
      const order = setting.order;
      const sort = setting.sort;
      const offset = page;
      const limit = setting.pageSize;

      if (!props.isReSearch || page > 1 || page == prevPage) {
        emit("do-search", offset, limit, order, sort);
      }
    };

    watch(() => setting.page, changePage);
    watch(
      () => props.page,
      val => {
        if (val <= 1) {
          setting.page = 1;
          emit("get-now-page", setting.page);
        } else if (val >= setting.maxPage) {
          setting.page = setting.maxPage;
          emit("get-now-page", setting.page);
        } else {
          setting.page = val;
        }
      },
    );
    const changePageSize = () => {
      if (setting.page === 1) {
        changePage(setting.page, setting.page);
      } else {
        setting.page = 1;
        setting.isCheckAll = false;
      }
    };
    watch(() => setting.pageSize, changePageSize);
    const prevPage = () => {
      if (setting.page == 1) {
        return false;
      }
      setting.page--;
    };
    const movePage = (page: number) => {
      setting.page = page;
    };
    const nextPage = () => {
      if (setting.page >= setting.maxPage) {
        return false;
      }
      setting.page++;
    };
    watch(
      () => props.rows,
      () => {
        if (props.isReSearch || props.isStaticMode) {
          setting.page = 1;
        }
        nextTick(function () {
          if (!props.isStaticMode) {
            callIsFinished();
          }
        });
      },
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stringFormat = (template: string, ...args: any[]) => {
      return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
      });
    };
    const callIsFinished = () => {
      if (localTable.value) {
        const localElement = localTable.value.getElementsByClassName("is-rows-el");
        emit("is-finished", localElement);
      }
      emit("get-now-page", setting.page);
    };
    onMounted(() => {
      nextTick(() => {
        if (props.rows.length > 0) {
          callIsFinished();
        }
      });
    });
    if (props.hasCheckbox) {
      return {
        slots,
        localTable,
        localRows,
        setting,
        rowCheckbox,
        checked,
        doSort,
        prevPage,
        movePage,
        nextPage,
        stringFormat,
      };
    } else {
      return {
        slots,
        localTable,
        localRows,
        setting,
        doSort,
        prevPage,
        movePage,
        nextPage,
        stringFormat,
      };
    }
  },
});
