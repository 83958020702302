const modules = {
  dashboard: "DASHBOARD",
  productCategory: "CATEGORY_PRODUCT",
  customer: "CUSTOMER",
  faq: "FAQ",
  newsletter: "NEWSLETTER",
  order: "ORDER",
  paymentType: "PAYMENT_TYPE",
  payment: "PAYMENT",
  platform: "PLATFORM",
  product: "PRODUCT",
  productType: "PRODUCT_TYPE",
  users: "USERS",
  systemParam: "SYSTEM_PARAM",
  termsUse: "TERMS_USE",
  loginHistory: "LOGIN_HISTORY",
  reports: "REPORTS",
};

export default modules;
