import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat.js";
import isBetween from "dayjs/plugin/isBetween.js";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.tz.setDefault("UTC");

export class UtilDate {
  static getActualDate = (format = "YYYYMMDD", timezone?: string): string => dayjs.tz(timezone || "UTC").format(format);

  static getPreviousDate = (days: number, format = "YYYYMMDD", timezone?: string): string =>
    dayjs
      .tz(timezone || "UTC")
      .subtract(days, "days")
      .format(format);

  static timeStampToDate = (timeStamp: number, timezone?: string): string =>
    dayjs.tz(dayjs.unix(timeStamp), timezone || "UTC").format("YYYY-MM-DD HH:mm");

  static format = (date: string | Date, format = "YYYY-MM-DD HH:mm:ss", timezone?: string): string =>
    dayjs.tz(date, timezone || "UTC").format(format);

  static formatToIsoString = (date: string | Date, timezone?: string): string =>
    dayjs(date)
      .tz(timezone || "UTC")
      .toISOString();

  static stringToDate = (date: string, format = "YYYY-MM-DD HH:mm:ss", timezone?: string): Date =>
    dayjs.tz(date, format, timezone || "UTC").toDate();

  static parse = (date: string, format = "YYYY-MM-DD HH:mm:ss", timezone?: string): Date =>
    UtilDate.stringToDate(date, format, timezone);

  static isBefore = (date: string | Date, ref: string | Date, timezone?: string): boolean =>
    dayjs.tz(date, timezone || "UTC").isBefore(ref);

  static isBeforeOrSame = (date: string | Date, ref: string | Date, timezone?: string): boolean =>
    this.isBefore(date, ref, timezone) || this.isSame(date, ref, timezone);

  static isAfter = (date: string | Date, ref: string | Date, timezone?: string): boolean =>
    dayjs.tz(date, timezone || "UTC").isAfter(ref);

  static isAfterOrSame = (date: string | Date, ref: string | Date, timezone?: string): boolean =>
    this.isAfter(date, ref, timezone) || this.isSame(date, ref, timezone);

  static isSame = (date: string | Date, ref: string | Date, timezone?: string): boolean =>
    dayjs.tz(date, timezone || "UTC").isSame(ref);

  static isBetween = (date: string | Date, start: string | Date, end: string | Date): boolean =>
    dayjs(date).isBetween(start, end, null, "[]");

  static isBetweenByType = (
    date: string | Date,
    start: number,
    end: number,
    type: "year" | "month" | "day" | "hour" | "minute" | "second",
  ): boolean => {
    const dt = dayjs(date);
    const dt_start = dt.set(type, start).startOf(type);
    const dt_end = dt.set(type, end).startOf(type);

    return dayjs(date).isBetween(dt_start, dt_end, null, "[]");
  };

  static getStartOf = (
    date: string | Date,
    type: "year" | "month" | "day" | "hour" | "minute" | "second",
    timezone?: string,
  ): Date => {
    return dayjs
      .tz(date, timezone || "UTC")
      .startOf(type)
      .toDate();
  };

  static getEndOf = (
    date: string | Date,
    type: "year" | "month" | "day" | "hour" | "minute" | "second",
    timezone?: string,
  ): Date => {
    return dayjs
      .tz(date, timezone || "UTC")
      .endOf(type)
      .toDate();
  };

  static add = (
    date: string | Date,
    quantity: number,
    type: "year" | "month" | "day" | "hour" | "minute" | "second",
    timezone?: string,
  ): Date => {
    return dayjs
      .tz(date, timezone || "UTC")
      .add(quantity, type)
      .toDate();
  };

  static diff = (
    start: string | Date,
    end: string | Date,
    type: "year" | "month" | "day" | "week" | "hour" | "minute" | "second",
    timezone?: string,
  ): number => {
    const dt_start = dayjs.tz(start, timezone || "UTC");
    const dt_end = dayjs.tz(end, timezone) || "UTC";

    return dt_start.diff(dt_end, type);
  };
}
