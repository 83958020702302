export const formatQueryString = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: Record<string, unknown> | string,
  filterObject = false,
): string => {
  let query = "";

  if (offset) {
    query += `&page=${offset}`;
  }

  if (limit) {
    query += `&per_page=${limit}`;
  }

  if (order) {
    query += `&sort=${order}`;

    if (sort) {
      query += `|${sort}`;
    }
  }

  if (filter) {
    if (typeof filter === "string") {
      query += `&filter=${filter}`;
    } else {
      Object.keys(filter).forEach(i => {
        if (![undefined, null, ""].includes(filter[i] as string)) {
          if (filterObject) {
            query += `&filter[${i}]=${filter[i]}`;
          } else {
            query += `&${i}=${filter[i]}`;
          }
        }
      });
    }
  }

  if (query) {
    query = `?${query.slice(1)}`;
  }

  return query;
};
