import { Options } from "vue-class-component";

import config from "@/config/config";
import PageBaseView from "@/pages/PageBaseView";
import { Customer } from "@/domain/customer.model";

@Options({
  name: "customer-view",
  props: {
    id: String,
  },
})
export default class CustomerView extends PageBaseView {
  public row: Customer = {
    name: "",
    email: "",
    accept_newsletter: false,
    accept_terms_use: false,
    enabled: true,
  };

  public mounted(): void {
    this.url = "customer";
    this.module = config.modules.customer;

    this.$store
      .dispatch("customer/get", this.id)
      .then((res: Customer) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }
}
