import { TermsUse } from "@/domain/terms-use.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import TermsUseService from "@/services/terms-use.service";

export default {
  async list({ commit }, query = null) {
    commit("setTermsUseIsLoading", true);

    return TermsUseService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<TermsUse>) => {
        commit("setTermsUses", res);
        commit("setTermsUseIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setTermsUseIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setTermsUseIsLoading", true);

    return TermsUseService.get(id)
      .then((res: TermsUse) => {
        commit("setTermsUse", res);
        commit("setTermsUseIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setTermsUseIsLoading", false);
      });
  },

  async insert({ commit }, termsUse) {
    commit("setTermsUseIsCreating", true);

    return TermsUseService.insert({
      description: termsUse.description,
      enabled: termsUse.enabled,
    })
      .then(() => {
        commit("setTermsUseIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setTermsUseIsCreating", false);
      });
  },

  async update({ commit }, termsUse) {
    commit("setTermsUseIsUpdating", true);

    return TermsUseService.update({
      id: termsUse.id,
      description: termsUse.description,
      enabled: termsUse.enabled,
    })
      .then(() => {
        commit("setTermsUseIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setTermsUseIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setTermsUseIsLoading", true);

    return TermsUseService.delete(id)
      .then((res: TermsUse) => {
        commit("setTermsUseIsDeleting", res);
        commit("setTermsUseIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setTermsUseIsLoading", false);
      });
  },
};
