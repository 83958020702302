import config from "@/config/config";
import { headers } from "./auth.headers";
import { formatQueryString } from "@/helpers/query-string";
import { Customer } from "@/domain/customer.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import httpClient from "@/helpers/http-client";
import { Product } from "@/domain/product.model";
import { UserReport } from "@/domain/users.model";

export default class CustomerService {
  private static urlApiBase = config.url_api_base;

  static getReportCustomerNewsletter = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: Record<string, unknown>,
  ): Promise<IPaginateVueTable<Customer>> => {
    const query = formatQueryString(offset, limit, order, sort, filter, true);
    const url = `${this.urlApiBase}/reports/customer-newsletter${query}`;

    return httpClient
      .request<IPaginateVueTable<Customer>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static exportReportCustomerNewsletter = (filter?: Record<string, unknown>): Promise<Customer[]> => {
    const query = formatQueryString(undefined, undefined, undefined, undefined, filter, true);
    const url = `${this.urlApiBase}/reports/customer-newsletter/export${query}`;

    return httpClient
      .request<Customer[]>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static getReportProduct = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: Record<string, unknown>,
  ): Promise<IPaginateVueTable<Product>> => {
    const query = formatQueryString(offset, limit, order, sort, filter, true);
    const url = `${this.urlApiBase}/reports/product${query}`;

    return httpClient
      .request<IPaginateVueTable<Product>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static exportReportProduct = (filter?: Record<string, unknown>): Promise<Product[]> => {
    const query = formatQueryString(undefined, undefined, undefined, undefined, filter, true);
    const url = `${this.urlApiBase}/reports/product/export${query}`;

    return httpClient
      .request<Product[]>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static getReportUserRegister = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: Record<string, unknown>,
  ): Promise<IPaginateVueTable<UserReport>> => {
    const query = formatQueryString(offset, limit, order, sort, filter, true);
    const url = `${this.urlApiBase}/reports/user-register${query}`;

    return httpClient
      .request<IPaginateVueTable<UserReport>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static exportReportUserRegister = (filter?: Record<string, unknown>): Promise<UserReport[]> => {
    const query = formatQueryString(undefined, undefined, undefined, undefined, filter, true);
    const url = `${this.urlApiBase}/reports/user-register/export${query}`;

    return httpClient
      .request<UserReport[]>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };
}
