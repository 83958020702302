/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import { Options, Vue } from "vue-class-component";

@Options({
  name: "app-menu-item",
  props: {
    menuItem: Object,
    icon: String,
    type: String,
    placeholder: String,
    autocomplete: String,
  },
})
export default class MenuItem extends Vue {
  private menuItem: any;
  private isMenuExtended: { [key: string]: boolean } = {};
  private isExpandable: boolean = false;
  private isMainActive: boolean = false;
  private isOneOfChildrenActive: boolean = false;

  public mounted(): void {
    this.isExpandable = this.menuItem?.children?.length > 0;
    this.calculateIsActive(this.$router.currentRoute.value.path);
    this.$router.afterEach(to => {
      this.calculateIsActive(to.path);
    });

    this.mapMenuExtended(this.menuItem);
  }

  private mapMenuExtended(item: any) {
    this.isMenuExtended[item.path] = false;
    if (item?.children?.length > 0) {
      for (const sub of item?.children) {
        this.mapMenuExtended(sub);
      }
    }
  }

  public handleMainMenuAction(item: any) {
    if (this.isExpandable) {
      this.toggleMenu(item.path);
      return;
    }
    this.$router.replace(this.menuItem.path);
  }

  public toggleMenu(k: string) {
    this.isMenuExtended[k] = !this.isMenuExtended[k];
  }

  public calculateIsActive(url: string) {
    this.isMainActive = false;
    this.isOneOfChildrenActive = false;
    if (this.isExpandable) {
      this.menuItem.children.forEach((item: any) => {
        if (item.path === url) {
          this.isOneOfChildrenActive = true;
          this.isMenuExtended[url] = true;
        }
      });
    } else if (this.menuItem.path === url) {
      this.isMainActive = true;
    }
    // if (!this.isMainActive && !this.isOneOfChildrenActive) {
    //   this.isMenuExtended = false;
    // }
  }
}
