import config from "@/config/config";

import Index from "@/pages/product/index.vue";
import List from "@/pages/product/list/list.vue";
import View from "@/pages/product/view/view.vue";
import New from "@/pages/product/new/new.vue";
import Edit from "@/pages/product/edit/edit.vue";
import NewPlatform from "@/pages/platform/new/new.vue";
import EditPlatform from "@/pages/platform/edit/edit.vue";
import ViewPlatform from "@/pages/platform/view/view.vue";

const modulePermission = config.modules.product;
const modulePermissionPlatform = config.modules.platform;

export default {
  path: "/product",
  name: "product",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "list",
      name: "product-list",
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: ":id",
      name: "product-view",
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
      props: true,
    },
    {
      path: "new",
      name: "product-new",
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_create",
      },
    },
    {
      path: ":id/e",
      name: "product-edit",
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_edit",
      },
      props: true,
    },
    {
      path: ":id/platform/new",
      name: "platform-new",
      component: NewPlatform,
      meta: {
        modulePermission: modulePermissionPlatform,
        requiresPermission: "enabled_create",
      },
      props: true,
    },
    {
      path: ":idProduct/platform/:id/e",
      name: "platform-edit",
      component: EditPlatform,
      meta: {
        modulePermission: modulePermissionPlatform,
        requiresPermission: "enabled_edit",
      },
      props: true,
    },
    {
      path: ":idProduct/platform/:id",
      name: "platform-view",
      component: ViewPlatform,
      meta: {
        modulePermission: modulePermissionPlatform,
        requiresPermission: "enabled_read",
      },
      props: true,
    },
  ],
};
