import { reactive } from "vue";
import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import { Platform } from "@/domain/platform.model";

const { t } = i18n.global;

@Options({
  name: "platform-list",
  components: {
    TableLite,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.platform.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.platform.platforms?.total;
    },
    platforms(): Platform[] {
      return this.$store.state.platform.platforms?.data;
    },
  },
  watch: {
    platforms: function () {
      this.table.rows = this.platforms;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class PlatformList extends PageBaseList {
  public table = TABLE;

  public mounted(): void {
    this.url = "platform";
    this.module = config.modules.platform;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public computed(): void {
    this.url = "platform";
    this.module = config.modules.platform;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: string) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    this.$store
      .dispatch("platform/list", { offset, limit, order, sort, filter })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public excluir(id: string | number) {
    this.$store
      .dispatch("platform/delete", id)
      .then(() => {
        this.table.isLoading;
      })
      .catch(() => {
        this.errorMessage = "Ocorreu um erro ao excluir.";
      });
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.id"),
      field: "id",
      sortable: true,
      isKey: true,
    },
    {
      label: "Produto",
      field: "product.description",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: "Nome",
      field: "name",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: "Código de vídeo externo",
      field: "code_video_external",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: "URL",
      field: "url",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.active"),
      field: "enabled",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      field: "actions",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "id",
    sort: "asc",
  },
});
