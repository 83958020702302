import { Payment } from "@/domain/payment.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import PaymentService from "@/services/payment.service";

export default {
  async list({ commit }, query = null) {
    commit("setPaymentIsLoading", true);

    return PaymentService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<Payment>) => {
        commit("setPayments", res);
        commit("setPaymentIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setPaymentIsLoading", true);

    return PaymentService.get(id)
      .then((res: Payment) => {
        commit("setPayment", res);
        commit("setPaymentIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentIsLoading", false);
      });
  },

  async insert({ commit }, payment) {
    commit("setPaymentIsCreating", true);

    return PaymentService.insert({
      id_payment_attempt: payment.id_payment_attempt,
      amount: payment.amount,
      type: payment.type,
      transaction_code: payment.transaction_code,
      transaction_date: payment.transaction_date,
    })
      .then(() => {
        commit("setPaymentIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentIsCreating", false);
      });
  },

  async update({ commit }, payment) {
    commit("setPaymentIsUpdating", true);

    return PaymentService.update({
      id: payment.id,
      id_payment_attempt: payment.id_payment_attempt,
      amount: payment.amount,
      type: payment.type,
      transaction_code: payment.transaction_code,
      transaction_date: payment.transaction_date,
    })
      .then(() => {
        commit("setPaymentIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setPaymentIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setPaymentIsLoading", true);

    return PaymentService.delete(id)
      .then((res: Payment) => {
        commit("setPaymentIsDeleting", res);
        commit("setPaymentIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentIsLoading", false);
      });
  },
};
