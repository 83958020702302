import { reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { isEmail } from "node-simple-validator";
import Toggle from "@vueform/toggle";
const { t } = i18n.global;

import { IList } from "@/domain/list.model";
import { Customer } from "@/domain/customer.model";
import i18n from "@/translation/i18n";

@Options({
  name: "users-new",
  components: {
    Toggle,
  },
})
export default class SystemUserNew extends Vue {
  public errorMessage = "";
  public errors: any = {};
  public hasError = false;
  public Password = false;
  public customers: IList[] = reactive([]);
  public row = {
    idCustomer: 0,
    name: "",
    email: "",
    username: "",
    password: "",
    type: "",
    enabled: true,
  };
  public types = [
    { id: 1, description: t('labels.user.type.admin') },
    { id: 2, description: t('labels.user.type.customer') },
  ]

  public created(): void {
    this.$store.dispatch("customer/listActive").then((res: Customer[]) => {
      this.customers = res.map(i => {
        return { id: i.id, description: i.name };
      });
    });
  }

  public togglePasswordVisibility() {
    this.Password = !this.Password;
  }

  public goBack() {
    this.$router.push("/users");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = t("labels.errors");
      this.hasError = true;
    } else if (!isEmail(this.row.email)) {
      this.errors.email = t("labels.errorsEmail");
      this.hasError = true;
    }

    if (!this.row.username) {
      this.errors.username = t("labels.errors");
      this.hasError = true;
    } else if (!isEmail(this.row.username)) {
      this.errors.username = t("labels.errorsLogin");
      this.hasError = true;
    }

    if (!this.row.password) {
      this.errors.password = t("labels.errors");
      this.hasError = true;
    }

    if (this.row.password.length < 6) {
      this.errors.password = t("labels.errorsPassword");
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("users/insert", {
          idCustomer: this.row.idCustomer,
          name: this.row.name,
          email: this.row.email,
          username: this.row.username,
          password: this.row.password,
          type: parseInt(this.row.type),
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/users");
        })
        .catch(() => {
          this.errorMessage = t("labels.errorMessage");
        });
    }
  }
}
