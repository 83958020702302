export default {
  setPaymentTypes: (state, paymentTypes) => {
    state.paymentTypes = paymentTypes;
  },
  setPaymentType: (state, paymentType) => {
    state.paymentType = paymentType;
  },
  setPaymentTypeIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setPaymentTypeIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setPaymentTypeIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setPaymentTypeIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
