import modules from "./modules";

const menu = [
  {
    name: "labels.menu.dashboard",
    path: "/",
    icon: "fas fa-tachometer-alt",
    // modulePermission: modules.dashboard,
  },
  {
    name: "labels.menu.customer",
    path: "/customer",
    icon: "fas fa-user",
    // modulePermission: modules.customer,
  },
  {
    name: "labels.menu.order",
    path: "/order",
    icon: "fas fa-box",
    // modulePermission: modules.order,
  },
  {
    name: "labels.menu.payment",
    path: "/payment",
    icon: "fas fa-credit-card",
    // modulePermission: modules.payment,
  },
  {
    name: "labels.menu.product",
    path: "/product",
    icon: "fas fa-box-open",
    // modulePermission: modules.product,
  },
  {
    name: "labels.menu.newsletter",
    path: "/newsletter",
    icon: "fas fa-envelope",
    // modulePermission: modules.newsletter,
  },
  {
    name: "labels.menu.loginHistory",
    path: "/login-history",
    icon: "fas fa-user-clock",
    // modulePermission: modules.loginHistory,
  },
  {
    name: "labels.menu.reports",
    path: "#reports",
    icon: "fas fa-chart-line",
    children: [
      {
        name: "labels.menu.reportsCustomerNewsletter",
        path: "/reports/customer-newslleter",
        icon: "fas fa-user",
        // modulePermission: modules.reports,
      },
      {
        name: "labels.menu.product",
        path: "/reports/product",
        icon: "fas fa-user",
        // modulePermission: modules.reports,
      },
      {
        name: "labels.menu.reportsUserRegister",
        path: "/reports/user-register",
        icon: "fas fa-user",
        // modulePermission: modules.reports,
      },
    ],
  },
  {
    name: "labels.menu.config",
    icon: "fas fa-cogs",
    path: "#config",
    children: [
      {
        name: "labels.menu.productCategory",
        path: "/product-category",
        icon: "fas fa-dolly",
        // modulePermission: modules.productCategory,
      },
      {
        name: "labels.menu.paymentType",
        path: "/payment-type",
        icon: "far fa-circle",
        // modulePermission: modules.paymentType,
      },
      {
        name: "labels.menu.productType",
        path: "/product-type",
        icon: "far fa-circle",
        // modulePermission: modules.productType,
      },
      // {
      //   name: "labels.menu.faq",
      //   path: "/faq",
      //   icon: "fas fa-question",
      //   // modulePermission: modules.faq,
      // },
      {
        name: "labels.menu.users",
        path: "/users",
        icon: "far fa-circle",
        // modulePermission: modules.users,
      },
      {
        name: "labels.menu.systemParam",
        path: "/system-param",
        icon: "far fa-circle",
        // modulePermission: modules.systemParam,
      },
    ],
  },
];

export default menu;
