import { ErrorType } from "@/interfaces/error";

const errors: ErrorType = {
  cnpj_already_exists: "Atenção! Este cliente já foi cadastrado anteriormente.",
};

export const exists = (key: string): boolean => {
  return key in errors;
};

export const get = (key: string): string | null => {
  return exists(key) ? errors[key] : null;
};

export default errors;
