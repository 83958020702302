import config from "@/config/config";
import { headers } from "./auth.headers";
import { formatQueryString } from "@/helpers/query-string";
import { Newsletter } from "@/domain/newsletter.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import httpClient from "@/helpers/http-client";

export default class NewsletterService {
  private static urlApiBase = config.url_api_base;

  static insert = (data: Newsletter): Promise<Newsletter> => {
    return httpClient
      .request<Newsletter>({
        url: `${this.urlApiBase}/newsletter`,
        method: "POST",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static update = (data: Newsletter): Promise<Newsletter> => {
    return httpClient
      .request<Newsletter>({
        url: `${this.urlApiBase}/newsletter/${data.id}`,
        method: "PATCH",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static get = (id: string): Promise<Newsletter> => {
    const url = `${this.urlApiBase}/newsletter/${id}`;

    return httpClient
      .request<Newsletter>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static list = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string,
  ): Promise<IPaginateVueTable<Newsletter>> => {
    const query = formatQueryString(offset, limit, order, sort, filter);
    const url = `${this.urlApiBase}/newsletter${query}`;

    return httpClient
      .request<IPaginateVueTable<Newsletter>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static listActive = (): Promise<Newsletter[]> => {
    const url = `${this.urlApiBase}/newsletter/list-active`;

    return httpClient
      .request<Newsletter[]>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static delete = (id: string): Promise<Newsletter> => {
    const url = `${this.urlApiBase}/newsletter/${id}`;

    return httpClient
      .request<Newsletter>({
        url,
        method: "DELETE",
        headers: headers(),
      })
      .then(result => result?.body);
  };
}
