import { Options, Vue } from "vue-class-component";

import List from "@/pages/newsletter/list/list.vue";

@Options({
  name: "newsletter",
  components: {
    "page-list": List,
  },
})
export default class Newsletter extends Vue {
  public isIndexOnly() {
    return /^[/]?newsletter[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
