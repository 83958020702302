import { LoginModule } from "@/domain/login.model";
import { IAuthState } from "@/interfaces/state";
import { IUser } from "@/interfaces/user";

export default {
  setToken: (state: IAuthState, payload: string): void => {
    state.token = payload;
    localStorage.setItem("token", payload);
  },
  setExpiresIn: (state: IAuthState, payload: number): void => {
    state.expiresIn = payload;
    localStorage.setItem("expiresIn", `${payload}`);
  },
  setUser: (state: IAuthState, payload: IUser): void => {
    state.user = payload;
    localStorage.setItem("user", JSON.stringify(payload));
  },
  setModules: (state: IAuthState, payload: LoginModule): void => {
    state.modules = payload;
    localStorage.setItem("modules", JSON.stringify(payload));
  },
};
