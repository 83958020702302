import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_page_list = _resolveComponent("page-list")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_view, {
      class: "view",
      "keep-alive": "",
      transition: "",
      "transition-mode": "out-in"
    }),
    (_ctx.isIndexOnly())
      ? (_openBlock(), _createBlock(_component_page_list, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}