import config from "@/config/config";

import Index from "@/pages/login-history/index.vue";
import List from "@/pages/login-history/list/list.vue";

const modulePermission = config.modules.users;

export default {
  path: "/login-history",
  name: "login-history",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "list",
      name: "login-history-list",
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
  ],
};
