import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { ErrorType } from "@/interfaces/error";

@Options({
  name: "customer-new",
  components: {
    Toggle,
  },
})
export default class CustomerNew extends Vue {
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row = {
    name: "",
    email: "",
    accept_newsletter: false,
    accept_terms_use: false,
    enabled: true,
  };

  public goBack() {
    this.$router.push("/customer");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    // this.row.city = this.row.city?.trim();

    if (!this.row.name) {
      this.errors.name = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("customer/insert", {
          name: this.row.name,
          email: this.row.email,
          accept_newsletter: this.row.accept_newsletter,
          accept_terms_use: this.row.accept_terms_use,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/customer");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
