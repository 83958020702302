import { Options } from "vue-class-component";

import config from "@/config/config";
import PageBaseView from "@/pages/PageBaseView";
import { Platform } from "@/domain/platform.model";
import i18n from "@/translation/i18n";
const { t } = i18n.global;

@Options({
  name: "platform-view",
  props: {
    idProduct: String,
    id: String,
  },
})
export default class PlatformView extends PageBaseView {
  public idProduct: string;
  public row: Platform = {
    id_product: 0,
    name: "",
    code_video_external: "",
    url: "",
    enabled: false,
  };

  public mounted(): void {
    this.url = "platform";
    this.module = config.modules.platform;

    this.$store
      .dispatch("platform/get", this.id)
      .then((res: Platform) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = t("labels.noDataAvailable");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.noDataAvailable");
      });
  }

  public goBack() {
    this.$router.push(`/product/${this.idProduct}`);
  }

  public edit() {
    this.$router.push(`/product/${this.idProduct}/platform/${this.id}/e`);
  }
}
