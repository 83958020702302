import config from "@/config/config";

import Index from "@/pages/reports/index.vue";
import CustomerNewslleter from "@/pages/reports/customer-newslleter/index.vue";
import Product from "@/pages/reports/product/index.vue";
import UserRegister from "@/pages/reports/user-register/index.vue";

const modulePermission = config.modules.customer;

export default {
  path: "/reports",
  name: "reports",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "customer-newslleter",
      name: "reports-customer-newslleter",
      component: CustomerNewslleter,
      meta: {
        requiresAuth: true,
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: "product",
      name: "reports-product",
      component: Product,
      meta: {
        requiresAuth: true,
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: "user-register",
      name: "reports-register",
      component: UserRegister,
      meta: {
        requiresAuth: true,
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
  ],
};
