import config from "@/config/config";

import Index from "@/pages/order/index.vue";
import List from "@/pages/order/list/list.vue";
import View from "@/pages/order/view/view.vue";

const modulePermission = config.modules.order;

export default {
  path: "/order",
  name: "order",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "list",
      name: "order-list",
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: ":id",
      name: "order-view",
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
      props: true,
    },
  ],
};
