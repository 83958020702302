import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { Platform } from "@/domain/platform.model";
import { ErrorType } from "@/interfaces/error";
import { IList } from "@/domain/list.model";
import { reactive } from "vue";
import i18n from "@/translation/i18n";
const { t } = i18n.global;

@Options({
  name: "platform-edit",
  props: {
    idProduct: String,
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class PlatformEdit extends Vue {
  public id: string;
  public idProduct: string;
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public products: IList[] = reactive([]);
  public row: Platform = {
    id_product: 0,
    name: "",
    code_video_external: "",
    url: "",
    enabled: false,
  };

  public mounted(): void {
    this.$store
      .dispatch("platform/get", this.id)
      .then((res: Platform) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = t("labels.noDataAvailable");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.noDataAvailable");
      });
  }

  public goBack() {
    this.$router.push(`/product/${this.idProduct}`);
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.url) {
      this.errors.url = t("labels.errors");
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("platform/update", {
          id: parseInt(this.id),
          id_product: parseInt(this.idProduct),
          name: this.row.name,
          code_video_external: this.row.code_video_external,
          url: this.row.url,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push(`/product/${this.idProduct}`);
        })
        .catch(() => {
          this.errorMessage = t("labels.errorMessage");
        });
    }
  }
}
