export default {
  setPaymentAttempts: (state, paymentAttempts) => {
    state.paymentAttempts = paymentAttempts;
  },
  setPaymentAttempt: (state, paymentAttempt) => {
    state.paymentAttempt = paymentAttempt;
  },
  setPaymentAttemptIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setPaymentAttemptIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setPaymentAttemptIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setPaymentAttemptIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
