import { PaymentType } from "@/domain/payment-type.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  paymentTypes: (state): IPaginateVueTable<PaymentType> => state.paymentTypes,
  paymentType: (state): PaymentType => state.paymentType,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
