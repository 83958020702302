import { reactive } from "vue";
import { Options } from "vue-class-component";
import { parse, format } from "date-fns";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import { LoginHistory } from "@/domain/login-history.model";
import { UtilDate } from "@/helpers/date";

const { t } = i18n.global;

@Options({
  name: "login-history-list",
  components: {
    TableLite,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.loginHistory.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.loginHistory.loginHistorys?.total;
    },
    loginHistorys(): LoginHistory[] {
      return this.$store.state.loginHistory.loginHistorys?.data;
    },
  },
  watch: {
    loginHistorys: function () {
      this.table.rows = this.loginHistorys;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class LoginHistoryList extends PageBaseList {
  public format = "";
  public table = TABLE;
  public filterLoginHistory = {
    name: null,
    type: null,
    origin: null,
    dateIni: null,
    dateEnd: null,
    ip: null,
    device_type: null,
  };

  public listaExcelFields = {
    user: "user.name",
    type: "type",
    origin: "origin",
    ip: "ip",
    device_type: "device_type",
    date: "date",
  };

  public mounted(): void {
    this.url = "login-history";
    this.module = config.modules.loginHistory;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "date", "desc");
  }

  public computed(): void {
    this.url = "login-history";
    this.module = config.modules.loginHistory;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "date", "desc");
  }

  public doFilter() {
    this.doSearch(0, this.per_page, "date", "desc", this.filterLoginHistory);
  }

  public formatDate(date: string | Date): string {
    return date ? UtilDate.format(date, "DD/MM/YYYY HH:mm") : "";
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: Record<string, unknown>) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    const filterWithFormattedDates = {
      ...filter,
      dateIni: this.filterLoginHistory.dateIni
        ? format(parse(this.filterLoginHistory.dateIni, "dd/MM/yyyy", new Date()), "yyyy/MM/dd")
        : null,
      dateEnd: this.filterLoginHistory.dateEnd
        ? format(parse(this.filterLoginHistory.dateEnd, "dd/MM/yyyy", new Date()), "yyyy/MM/dd")
        : null,
    };

    this.$store
      .dispatch("loginHistory/list", { offset, limit, order, sort, filter: filterWithFormattedDates })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public gerarExcel(filter?: Record<string, unknown>) {
    return this.$store.dispatch("loginHistory/export", filter);
  }

  public clear() {
    this.filterLoginHistory = {
      name: null,
      type: null,
      origin: null,
      dateIni: null,
      dateEnd: null,
      ip: null,
      device_type: null,
    };
    this.doSearch(0, this.per_page, "date", "desc");
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.user"),
      field: "user.name",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.origin"),
      field: "origin",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.type"),
      field: "type",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.date"),
      field: "date",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.ip"),
      field: "ip",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.device_type"),
      field: "device_type",
      sortable: true,
      columnStyles: "text-align: center",
    },
  ],
  rows: [],
  totalRecordCount: 0,
  sortable: {
    order: "date",
    sort: "desc",
  },
});
