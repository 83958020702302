import { Customer } from "@/domain/customer.model";
import { Product } from "@/domain/product.model";
import { Users } from "@/domain/users.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  isLoading: (state): boolean => state.isLoading,
  reportCustomerNewsletter: (state): IPaginateVueTable<Customer> => state.reportCustomerNewsletter,
  reportCustomerNewsletterExport: (state): Customer => state.reportCustomerNewsletterExport,
  reportProduct: (state): IPaginateVueTable<Product> => state.reportProduct,
  reportProductExport: (state): Product => state.reportProductExport,
  reportUserRegister: (state): IPaginateVueTable<Users> => state.reportUserRegister,
  reportUserRegisterExport: (state): Users => state.reportUserRegisterExport,
};
