import config from "@/config/config";

import Index from "@/pages/config/system-param/index.vue";
import List from "@/pages/config/system-param/list/list.vue";
import View from "@/pages/config/system-param/view/view.vue";
import New from "@/pages/config/system-param/new/new.vue";
import Edit from "@/pages/config/system-param/edit/edit.vue";

const modulePermission = config.modules.systemParam;

export default {
  path: "/system-param",
  name: "system-param",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "list",
      name: "system-param-list",
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: ":param",
      name: "system-param-view",
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
      props: true,
    },
    {
      path: "new",
      name: "system-param-new",
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_create",
      },
    },
    {
      path: ":param/e",
      name: "system-param-edit",
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_edit",
      },
      props: true,
    },
  ],
};
