import { ProductCategory } from "@/domain/product-category.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  productCategorys: (state): IPaginateVueTable<ProductCategory> => state.productCategorys,
  productCategory: (state): ProductCategory => state.productCategory,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
