import { Options } from "vue-class-component";

import config from "@/config/config";
import PageBaseView from "@/pages/PageBaseView";
import { TermsUse } from "@/domain/terms-use.model";

@Options({
  name: "terms-use-view",
  props: {
    id: String,
  },
})
export default class TermsUseView extends PageBaseView {
  public row: TermsUse = {
    description: "",
    enabled: false,
  };

  public mounted(): void {
    this.url = "terms-use";
    this.module = config.modules.termsUse;

    this.$store
      .dispatch("termsUse/get", this.id)
      .then((res: TermsUse) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }
}
