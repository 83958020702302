export default {
  setNewsletters: (state, newsletters) => {
    state.newsletters = newsletters;
  },
  setNewsletter: (state, newsletter) => {
    state.newsletter = newsletter;
  },
  setNewsletterIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setNewsletterIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setNewsletterIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setNewsletterIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
