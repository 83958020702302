import { Product } from "@/domain/product.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  products: (state): IPaginateVueTable<Product> => state.products,
  product: (state): Product => state.product,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
