/* eslint-disable @typescript-eslint/no-explicit-any */
import * as errors from "./errors";
import menu from "./menu";
import modules from "./modules";
import version from "./version";

let config = Object.assign(version, {
  url_api_base: (window as any).__INITIAL_DATA__.url_api_base,
  app_url_base: (window as any).__INITIAL_DATA__.PUBLIC_URL,
  reCaptchaKey: (window as any).__INITIAL_DATA__.recaptcha_key,
  MAX_ATTACHMENT_SIZE: 10000000,
  menu,
  modules,
  errors,
});

export const load = (setup: any) => {
  config = Object.assign(config, setup);
};

export default config;
