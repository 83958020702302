import { Options, Vue } from "vue-class-component";

import List from "@/pages/config/product-type/list/list.vue";

@Options({
  name: "product-type",
  components: {
    "page-list": List,
  },
})
export default class ProductType extends Vue {
  public isIndexOnly() {
    return /^[/]?product-type[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
