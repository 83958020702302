import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseView from "@/pages/PageBaseView";
import { Product } from "@/domain/product.model";
import { ProductLanguage } from "@/domain/product-language.model";

const { t } = i18n.global;

@Options({
  name: "product-view",
  props: {
    id: String,
  },
})
export default class ProductView extends PageBaseView {
  public showDeletePlatform = false;
  public showInsertProductLanguage = false;
  public showEditProductLanguage = false;
  public showDeleteProductLanguage = false;
  public msgDeletePlatform = "";
  public msgDeleteProductLanguage = "";
  public idDeletePlatform = 0;
  public idDeleteProductLanguage = 0;
  public idLanguage = 0;
  public row: Product = {
    id_category: 0,
    type: 0,
    price: 0,
    discount: 0,
    description: "",
    image: "",
    is_highlight: false,
    image_highlight: "",
    platform_video_name: "",
    video_display_name: "",
    enabled: false,
    isFree: false,
  };

  public rowProductLanguage: ProductLanguage = {
    idProduct: 0,
    lang: "",
    name: "",
    description: "",
  };

  public mounted(): void {
    this.url = "product";
    this.module = config.modules.product;
    this.loading();
  }

  public loading() {
    this.$store
      .dispatch("product/get", this.id)
      .then((res: Product) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = t("labels.errors");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.errors");
      });
  }

  public formatNumber(value) {
    return value ? Number(value).toFixed(2) : "-";
  }

  public newPlatform() {
    this.$router.push(`${this.id}/platform/new`);
  }

  public editPlatform(id) {
    this.$router.push(`${this.id}/platform/${id}/e`);
  }

  public viewPlatform(id) {
    this.$router.push(`${this.id}/platform/${id}/`);
  }

  public deletePlatform(id: number, name: string) {
    this.showDeletePlatform = true;
    this.idDeletePlatform = id;
    this.msgDeletePlatform = t("labels.msgDeletePlatform") + ` ${name}`;
  }

  public deletePlatformConfirm() {
    this.$store
      .dispatch("platform/delete", this.idDeletePlatform)
      .then(() => {
        this.loading();
        this.closeModal();
      })
      .catch(() => {
        this.errorMessage = t("labels.errorMessageDelete");
      });
  }

  public newProductLanguage(): void {
    this.rowProductLanguage = {
      idProduct: 0,
      lang: "",
      name: "",
      description: "",
    };
    this.showInsertProductLanguage = true;
  }

  public editProductLanguage(id_language: number): void {
    this.showEditProductLanguage = true;
    this.idLanguage = id_language;
    this.$store
      .dispatch("productLanguage/get", this.idLanguage)
      .then((res: ProductLanguage) => {
        if (res && res.id) {
          this.rowProductLanguage = res;
        } else {
          this.errorMessage = t("labels.errors");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.errors");
      });
  }

  public valitationLanguage() {
    this.errorMessage = "";
    this.errors = {};
    this.hasError = false;

    if (!this.rowProductLanguage.lang) {
      this.errors.lang = t("labels.errors");
      this.hasError = true;
    }

    if (!this.rowProductLanguage.name) {
      this.errors.name = t("labels.errors");
      this.hasError = true;
    }

    if (!this.rowProductLanguage.description) {
      this.errors.description = t("labels.errors");
      this.hasError = true;
    }

    return !this.hasError;
  }

  public saveProductLanguage(newRow = true) {
    if (this.valitationLanguage()) {
      if (newRow) {
        this.$store
          .dispatch("productLanguage/insert", {
            idProduct: parseInt(this.id),
            lang: this.rowProductLanguage.lang,
            name: this.rowProductLanguage.name,
            description: this.rowProductLanguage.description,
          })
          .then(() => {
            this.closeModal();
            this.loading();
          })
          .catch(() => {
            this.errorMessage = t("labels.errorMessage");
          });
      } else {
        this.$store
          .dispatch("productLanguage/update", {
            id: this.idLanguage,
            idProduct: parseInt(this.id),
            lang: this.rowProductLanguage.lang,
            name: this.rowProductLanguage.name,
            description: this.rowProductLanguage.description,
          })
          .then(() => {
            this.closeModal();
            this.loading();
          })
          .catch(() => {
            this.errorMessage = t("labels.errorMessage");
          });
      }
    }
  }

  public deleteProductLanguage(id: number, name: string) {
    this.showDeleteProductLanguage = true;
    this.idDeleteProductLanguage = id;
    this.msgDeleteProductLanguage = t("labels.msgDeleteProductLanguage") + ` ${name}`;
  }

  public deleteProductLanguageConfirm() {
    this.$store
      .dispatch("productLanguage/delete", this.idDeleteProductLanguage)
      .then(() => {
        this.closeModal();
        this.loading();
      })
      .catch(() => {
        this.errorMessage = t("labels.errorMessageDelete");
      });
  }

  public closeModal() {
    this.showDeletePlatform = false;
    this.showInsertProductLanguage = false;
    this.showEditProductLanguage = false;
    this.showDeleteProductLanguage = false;
  }
}
