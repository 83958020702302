import { Options, Vue } from "vue-class-component";

import List from "@/pages/terms-use/list/list.vue";

@Options({
  name: "terms-use",
  components: {
    "page-list": List,
  },
})
export default class TermsUse extends Vue {
  public isIndexOnly() {
    return /^[/]?terms-use[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
