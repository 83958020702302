import config from "@/config/config";
import { headers } from "./auth.headers";
import { formatQueryString } from "@/helpers/query-string";
import { Customer } from "@/domain/customer.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import httpClient from "@/helpers/http-client";

export default class CustomerService {
  private static urlApiBase = config.url_api_base;

  static insert = (data: Customer): Promise<Customer> => {
    return httpClient
      .request<Customer>({
        url: `${this.urlApiBase}/customer`,
        method: "POST",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static update = (data: Customer): Promise<Customer> => {
    return httpClient
      .request<Customer>({
        url: `${this.urlApiBase}/customer/${data.id}`,
        method: "PATCH",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static get = (id: string): Promise<Customer> => {
    const url = `${this.urlApiBase}/customer/${id}`;

    return httpClient
      .request<Customer>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static list = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: Record<string, unknown>,
  ): Promise<IPaginateVueTable<Customer>> => {
    const query = formatQueryString(offset, limit, order, sort, filter, true);
    const url = `${this.urlApiBase}/customer${query}`;

    return httpClient
      .request<IPaginateVueTable<Customer>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static listActive = (): Promise<Customer[]> => {
    const url = `${this.urlApiBase}/customer/list-active`;

    return httpClient
      .request<Customer[]>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static delete = (id: string): Promise<Customer> => {
    const url = `${this.urlApiBase}/customer/${id}`;

    return httpClient
      .request<Customer>({
        url,
        method: "DELETE",
        headers: headers(),
      })
      .then(result => result?.body);
  };
}
