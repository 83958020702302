import { PaymentAttempt } from "@/domain/payment-attempt.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import PaymentAttemptService from "@/services/payment-attempt.service";

export default {
  async list({ commit }, query = null) {
    commit("setPaymentAttemptIsLoading", true);

    return PaymentAttemptService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<PaymentAttempt>) => {
        commit("setPaymentAttempts", res);
        commit("setPaymentAttemptIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentAttemptIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setPaymentAttemptIsLoading", true);

    return PaymentAttemptService.get(id)
      .then((res: PaymentAttempt) => {
        commit("setPaymentAttempt", res);
        commit("setPaymentAttemptIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentAttemptIsLoading", false);
      });
  },

  async listActive({ commit }) {
    commit("setPaymentAttemptIsLoading", true);

    return PaymentAttemptService.listActive()
      .then((res: PaymentAttempt[]) => {
        commit("setPaymentAttempt", res);
        commit("setPaymentAttemptIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductCategoryIsLoading", false);
      });
  },

  async insert({ commit }, paymentAttempt) {
    commit("setPaymentAttemptIsCreating", true);

    return PaymentAttemptService.insert({
      id_customer: paymentAttempt.id_customer,
      amount: paymentAttempt.amount,
      type: paymentAttempt.type,
      transaction_code: paymentAttempt.transaction_code,
      transaction_date: paymentAttempt.transaction_date,
      result: paymentAttempt.result,
    })
      .then(() => {
        commit("setPaymentAttemptIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentAttemptIsCreating", false);
      });
  },

  async update({ commit }, paymentAttempt) {
    commit("setPaymentAttemptIsUpdating", true);

    return PaymentAttemptService.update({
      id: paymentAttempt.id,
      id_customer: paymentAttempt.id_customer,
      amount: paymentAttempt.amount,
      type: paymentAttempt.type,
      transaction_code: paymentAttempt.transaction_code,
      transaction_date: paymentAttempt.transaction_date,
      result: paymentAttempt.result,
    })
      .then(() => {
        commit("setPaymentAttemptIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setPaymentAttemptIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setPaymentAttemptIsLoading", true);

    return PaymentAttemptService.delete(id)
      .then((res: PaymentAttempt) => {
        commit("setPaymentAttemptIsDeleting", res);
        commit("setPaymentAttemptIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setPaymentAttemptIsLoading", false);
      });
  },
};
