import { TermsUse } from "@/domain/terms-use.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  termsUses: (state): IPaginateVueTable<TermsUse> => state.termsUses,
  termsUse: (state): TermsUse => state.termsUse,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
