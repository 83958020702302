import { IUser } from "@/interfaces/user";
import { Options, Vue } from "vue-class-component";
import Dropdown from "@/components/dropdown/dropdown.vue";
import dayjs from "dayjs";

@Options({
  name: "user-dropdown",
  components: {
    "app-dropdown": Dropdown,
  },
})
export default class User extends Vue {
  get user(): IUser {
    return this.$store.getters["auth/user"];
  }

  private logout() {
    this.$store.dispatch("auth/logout");
  }

  get readableCreatedAtDate() {
    return this.user ? dayjs(this.user.createdAt).format("dd LLLL yyyy") : null;
  }
}
