export default {
  setProductCategorys: (state, productCategorys) => {
    state.productCategorys = productCategorys;
  },
  setProductCategory: (state, productCategory) => {
    state.productCategory = productCategory;
  },
  setProductCategoryIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setProductCategoryIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setProductCategoryIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setProductCategoryIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
