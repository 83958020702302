import { ProductLanguage } from "@/domain/product-language.model";
import ProductLanguageService from "@/services/product-language.service";

export default {
  async get({ commit }, id) {
    commit("setProductLanguageIsLoading", true);

    return ProductLanguageService.get(id)
      .then((res: ProductLanguage) => {
        commit("setProductLanguage", res);
        commit("setProductLanguageIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductLanguageIsLoading", false);
      });
  },

  async insert({ commit }, productLanguage) {
    commit("setProductLanguageIsCreating", true);

    return ProductLanguageService.insert({
      idProduct: productLanguage.idProduct,
      lang: productLanguage.lang,
      name: productLanguage.name,
      description: productLanguage.description,
    })
      .then(() => {
        commit("setProductLanguageIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductLanguageIsCreating", false);
        throw error;
      });
  },

  async update({ commit }, productLanguage) {
    commit("setProductLanguageIsUpdating", true);

    return ProductLanguageService.update({
      id: productLanguage.id,
      idProduct: productLanguage.idProduct,
      lang: productLanguage.lang,
      name: productLanguage.name,
      description: productLanguage.description,
    })
      .then(() => {
        commit("setProductLanguageIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setProductLanguageIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setProductLanguageIsLoading", true);

    return ProductLanguageService.delete(id)
      .then((res: ProductLanguage) => {
        commit("setProductLanguageIsDeleting", res);
        commit("setProductLanguageIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductLanguageIsLoading", false);
      });
  },
};
