import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const newsletterModule = {
  namespaced: true,
  state: {
    newsletters: null,
    newsletter: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  mutations,
  actions,
  getters,
};

export default newsletterModule;
