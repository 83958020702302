export default {
  setTermsUses: (state, termsUses) => {
    state.termsUses = termsUses;
  },
  setTermsUse: (state, termsUse) => {
    state.termsUse = termsUse;
  },
  setTermsUseIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setTermsUseIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setTermsUseIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setTermsUseIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
