import { reactive } from "vue";
import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import { SystemParam } from "@/domain/system-param.model";
import Toggle from "@vueform/toggle/src/Toggle";

const { t } = i18n.global;

@Options({
  name: "system-param-list",
  components: {
    TableLite,
    Toggle,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.systemParam.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.systemParam.systemParams?.total;
    },
    systemParams(): SystemParam[] {
      return this.$store.state.systemParam.systemParams?.data;
    },
  },
  watch: {
    systemParams: function () {
      this.table.rows = this.systemParams;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class systemParamList extends PageBaseList {
  public table = TABLE;
  public filterSystemParam = {
    param: "",
    value: "",
    description: "",
    enabled: true,
  };

  public mounted(): void {
    this.url = "system-param";
    this.module = config.modules.systemParam;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "param", "asc");
  }

  public computed(): void {
    this.url = "system-param";
    this.module = config.modules.systemParam;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "param", "asc");
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: any) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    this.$store
      .dispatch("systemParam/list", { offset, limit, order, sort, filter })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public doFilter() {
    this.doSearch(0, this.per_page, "param", "asc", this.filterSystemParam);
  }

  public clear() {
    this.filterSystemParam = {
      param: "",
      value: "",
      description: "",
      enabled: true,
    };
    this.doSearch(0, this.per_page, "param", "asc");
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.param"),
      field: "param",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.value"),
      field: "value",
      sortable: true,
      columnStyles: "text-align: left; max-width: 150px",
    },
    {
      label: t("labels.entity.description"),
      field: "description",
      sortable: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.active"),
      field: "enabled",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      field: "actions",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "param",
    sort: "asc",
  },
});
