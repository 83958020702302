import config from "@/config/config";
import { headers } from "./auth.headers";
import { formatQueryString } from "@/helpers/query-string";
import { Platform } from "@/domain/platform.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import httpClient from "@/helpers/http-client";

export default class PlatformService {
  private static urlApiBase = config.url_api_base;

  static insert = (data: Platform): Promise<Platform> => {
    return httpClient
      .request<Platform>({
        url: `${this.urlApiBase}/platform`,
        method: "POST",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static update = (data: Platform): Promise<Platform> => {
    return httpClient
      .request<Platform>({
        url: `${this.urlApiBase}/platform/${data.id}`,
        method: "PATCH",
        body: data,
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static get = (id: string): Promise<Platform> => {
    const url = `${this.urlApiBase}/platform/${id}`;

    return httpClient
      .request<Platform>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static list = (
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string,
  ): Promise<IPaginateVueTable<Platform>> => {
    const query = formatQueryString(offset, limit, order, sort, filter);
    const url = `${this.urlApiBase}/platform${query}`;

    return httpClient
      .request<IPaginateVueTable<Platform>>({
        url,
        method: "GET",
        headers: headers(),
      })
      .then(result => result?.body);
  };

  static delete = (id: string): Promise<Platform> => {
    const url = `${this.urlApiBase}/platform/${id}`;

    return httpClient
      .request<Platform>({
        url,
        method: "DELETE",
        headers: headers(),
      })
      .then(result => result?.body);
  };
}
