import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";

import { Customer } from "@/domain/customer.model";
import { ErrorType } from "@/interfaces/error";

@Options({
  name: "customer-edit",
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class CustomerEdit extends Vue {
  public id: string;
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row: Customer = {
    name: "",
    email: "",
    accept_newsletter: false,
    accept_terms_use: false,
    enabled: false,
  };

  public mounted(): void {
    this.$store
      .dispatch("customer/get", this.id)
      .then((res: Customer) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }

  public goBack() {
    this.$router.push("/customer");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = "Este campo deve ser preenchido.";
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("customer/update", {
          id: parseInt(this.id),
          name: this.row.name,
          email: this.row.email,
          accept_newsletter: this.row.accept_newsletter,
          accept_terms_use: this.row.accept_terms_use,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/customer");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
