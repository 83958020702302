import { Options } from "vue-class-component";

import config from "@/config/config";
import PageBaseView from "@/pages/PageBaseView";
import { Faq } from "@/domain/faq.model";

@Options({
  name: "faq-view",
  props: {
    id: String,
  },
})
export default class FaqView extends PageBaseView {
  public row: Faq = {
    question: "",
    answer: "",
    enabled: false,
  };

  public mounted(): void {
    this.url = "faq";
    this.module = config.modules.faq;

    this.$store
      .dispatch("faq/get", this.id)
      .then((res: Faq) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }
}
