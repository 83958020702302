import { Options } from "vue-class-component";

import config from "@/config/config";
import PageBaseView from "@/pages/PageBaseView";
import { PaymentType } from "@/domain/payment-type.model";

@Options({
  name: "payment-type-view",
  props: {
    id: String,
  },
})
export default class PaymentTypeView extends PageBaseView {
  public row: PaymentType = {
    description: "",
    enabled: false,
  };

  public mounted(): void {
    this.url = "payment-type";
    this.module = config.modules.paymentType;

    this.$store
      .dispatch("paymentType/get", this.id)
      .then((res: PaymentType) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = "Registro não encontrado.";
        }
      })
      .catch(() => {
        this.errorMessage = "Registro não encontrado.";
      });
  }
}
