import config from "@/config/config";

import Index from "@/pages/config/users/index.vue";
import List from "@/pages/config/users/list/list.vue";
import View from "@/pages/config/users/view/view.vue";
import New from "@/pages/config/users/new/new.vue";
import Edit from "@/pages/config/users/edit/edit.vue";

const modulePermission = config.modules.users;

export default {
  path: "/users",
  name: "users",
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: "enabled_read",
  },
  children: [
    {
      path: "list",
      name: "users-list",
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
    },
    {
      path: ":id",
      name: "users-view",
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_read",
      },
      props: true,
    },
    {
      path: "new",
      name: "users-new",
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_create",
      },
    },
    {
      path: ":id/e",
      name: "users-edit",
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: "enabled_edit",
      },
      props: true,
    },
  ],
};
