import { reactive } from "vue";
import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import { Users } from "@/domain/users.model";
import Toggle from "@vueform/toggle/src/Toggle";

const { t } = i18n.global;

@Options({
  name: "users-list",
  components: {
    TableLite,
    Toggle,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.users.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.users.userses?.total;
    },
    userses(): Users[] {
      return this.$store.state.users.userses?.data;
    },
  },
  watch: {
    userses: function () {
      this.table.rows = this.userses;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class UsersList extends PageBaseList {
  public table = TABLE;
  public filterUsers = {
    name: "",
    email: "",
    username: "",
    enabled: true,
  };

  public mounted(): void {
    this.url = "users";
    this.module = config.modules.users;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public computed(): void {
    this.url = "users";
    this.module = config.modules.users;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: any) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    this.$store
      .dispatch("users/list", { offset, limit, order, sort, filter })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public doFilter() {
    this.doSearch(0, this.per_page, "id", "asc", this.filterUsers);
  }

  public clear() {
    this.filterUsers = {
      name: "",
      email: "",
      username: "",
      enabled: true,
    };
    this.doSearch(0, this.per_page, "id", "asc");
  }

  public excluir(id: string | number) {
    this.$store
      .dispatch("ursers/delete", id)
      .then(() => {
        this.table.isLoading;
      })
      .catch(() => {
        this.errorMessage = t("labels.errorMessageDelete");
      });
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.name"),
      field: "name",
      sortable: true,
      isKey: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.customer"),
      field: "customer.name",
      sortable: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.username"),
      field: "username",
      sortable: true,
      columnStyles: "text-align: left",
    },
    {
      label: t("labels.entity.type"),
      field: "type",
      sortable: true,
    },
    {
      label: t("labels.entity.active"),
      field: "enabled",
      sortable: true,
    },
    {
      field: "actions",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "id",
    sort: "asc",
  },
});
