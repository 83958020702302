import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";

import config from "@/config/config";

@Options({})
export default class Footer extends Vue {
  private version: string = config.version_pwa;
  private currentYear: string = String(dayjs().year());
}
