import axios, { AxiosHeaders, AxiosResponse } from "axios";

import { IHttpClient, HttpRequest, HttpResponse } from "./request.types";
// import router from "@/router";
import store from "@/store/index";

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
      // router.push("/login");
    }
    return Promise.reject(error);
  },
);

class HttpClient implements IHttpClient {
  public request<T>(data: HttpRequest): Promise<HttpResponse<T>> {
    return axios
      .request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: data.headers as AxiosHeaders,
      })
      .then((res: AxiosResponse<T>) => {
        return {
          body: res.data,
        };
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
}

export default new HttpClient();
