import { Options } from "vue-class-component";

import config from "@/config/config";
import { SystemParam } from "@/domain/system-param.model";
import PageBaseView from "@/pages/PageBaseView";
import i18n from "@/translation/i18n";

const { t } = i18n.global;

@Options({
  name: "system-param-view",
  props: {
    param: String,
  },
})
export default class SystemParamView extends PageBaseView {
  public param: string;
  public row: SystemParam = {
    value: "",
    description: "",
    enabled: true,
  };

  public mounted(): void {
    this.url = "system-param";
    this.module = config.modules.systemParam;

    this.$store
      .dispatch("systemParam/get", this.param)
      .then((res: SystemParam) => {
        if (res && res.param) {
          console.log(this.param);
          this.row = res;
        } else {
          this.errorMessage = t("labels.noDataAvailable");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.noDataAvailable");
      });
  }

  public edit() {
    this.$router.push(`/${this.url}/${this.param}/e`);
  }
}
