import { Options, Vue } from "vue-class-component";
import Toggle from "@vueform/toggle";
const { t } = i18n.global;

import { ErrorType } from "@/interfaces/error";
import i18n from "@/translation/i18n";


@Options({
  name: "system-param-new",
  components: {
    Toggle,
  },
})
export default class SystemParamNew extends Vue {
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public row = {
    param: "",
    value: "",
    description: "",
    enabled: true,
  };

  public goBack() {
    this.$router.push("system-param");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.param) {
      this.errors.param = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.value) {
      this.errors.value = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = t("labels.errors");
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("systemParam/insert", {
          param: this.row.param,
          value: this.row.value,
          description: this.row.description,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push("/system-param");
        })
        .catch(() => {
          this.errorMessage = "Ocorreu um erro ao gravar o registro.";
        });
    }
  }
}
