/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue } from "vue-class-component";

import { validatePermission } from "@/helpers/module-permission";

export default class PageBaseList extends Vue {
  public url = "";
  public filter = "";
  public per_page = 25;
  public max_per_page = 100;
  public module = "";
  public errorMessage = "";

  public hasPermission(permission: string): boolean {
    return validatePermission(this.module, permission);
  }

  public doSearch(
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: Record<string, unknown> | string,
  ) {
    return;
  }

  public reload() {
    this.doSearch(0, this.per_page, "id", "asc", this.filter);
  }

  public doFilter() {
    if (this.filter.length == 0 || this.filter.length >= 2) {
      this.doSearch(0, this.per_page, "id", "asc", this.filter);
    }
  }

  public newRow() {
    this.$router.push(`/${this.url}/new`);
  }

  public edit(id: string | number) {
    this.$router.push(`/${this.url}/${id}/e`);
  }

  public view(id: string | number) {
    this.$router.push(`/${this.url}/${id}`);
  }
}
