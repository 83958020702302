import { ProductType } from "@/domain/product-type.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";
import ProductTypeService from "@/services/product-type.service";

export default {
  async list({ commit }, query = null) {
    commit("setProductTypeIsLoading", true);

    return ProductTypeService.list(query?.offset || 1, query?.limit || 25, query?.order, query?.sort, query?.filter)
      .then((res: IPaginateVueTable<ProductType>) => {
        commit("setProductTypes", res);
        commit("setProductTypeIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductTypeIsLoading", false);
      });
  },

  async listActive({ commit }) {
    commit("setProductTypeIsLoading", true);

    return ProductTypeService.listActive()
      .then((res: ProductType[]) => {
        commit("setProductType", res);
        commit("setProductTypeIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductTypeIsLoading", false);
      });
  },

  async get({ commit }, id) {
    commit("setProductTypeIsLoading", true);

    return ProductTypeService.get(id)
      .then((res: ProductType) => {
        commit("setProductType", res);
        commit("setProductTypeIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductTypeIsLoading", false);
      });
  },

  async insert({ commit }, productType) {
    commit("setProductTypeIsCreating", true);

    return ProductTypeService.insert({
      description: productType.description,
      enabled: productType.enabled,
    })
      .then(() => {
        commit("setProductTypeIsCreating", false);
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductTypeIsCreating", false);
      });
  },

  async update({ commit }, productType) {
    commit("setProductTypeIsUpdating", true);

    return ProductTypeService.update({
      id: productType.id,
      description: productType.description,
      enabled: productType.enabled,
    })
      .then(() => {
        commit("setProductTypeIsUpdating", false);
      })
      .catch(err => {
        console.log("error", err);
        commit("setProductTypeIsUpdating", false);
      });
  },

  async delete({ commit }, id) {
    commit("setProductTypeIsLoading", true);

    return ProductTypeService.delete(id)
      .then((res: ProductType) => {
        commit("setProductTypeIsDeleting", res);
        commit("setProductTypeIsLoading", false);

        return res;
      })
      .catch(error => {
        console.log("error", error);
        commit("setProductTypeIsLoading", false);
      });
  },
};
