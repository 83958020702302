import { reactive } from "vue";
import { Options } from "vue-class-component";

import config from "@/config/config";
import i18n from "@/translation/i18n";
import PageBaseList from "@/pages/PageBaseList";
import TableLite from "@/components/table/TableLiteTs.vue";
import { Product } from "@/domain/product.model";
import { IList } from "@/domain/list.model";
import { ProductCategory } from "@/domain/product-category.model";
import { ProductType } from "@/domain/product-type.model";

const { t } = i18n.global;

@Options({
  name: "product-list",
  components: {
    TableLite,
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.product.isLoading;
    },
    totalRecordCount(): number {
      return this.$store.state.product.products?.total;
    },
    products(): Product[] {
      return this.$store.state.product.products?.data;
    },
  },
  watch: {
    products: function () {
      this.table.rows = this.products;
      this.table.totalRecordCount = this.totalRecordCount;
    },
    isLoading: function () {
      this.table.isLoading = this.isLoading;
    },
  },
})
export default class ProductList extends PageBaseList {
  public table = TABLE;
  public categorys: IList[] = reactive([]);
  public types: IList[] = reactive([]);
  public filterProduct = {
    id_category: null,
    type: null,
    priceIni: null,
    priceEnd: null,
    description: null,
    name: null,
    code_video_external: null,
  };

  public mounted(): void {
    this.url = "product";
    this.module = config.modules.product;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");

    this.$store.dispatch("productCategory/listActive").then((res: ProductCategory[]) => {
      this.categorys = res.map(i => {
        return { id: i.id, description: i.description };
      });
    });

    this.$store.dispatch("productType/listActive").then((res: ProductType[]) => {
      this.types = res.map(i => {
        return { id: i.id, description: i.description };
      });
    });
  }

  public computed(): void {
    this.url = "product";
    this.module = config.modules.product;
    this.table.rows = [];

    this.doSearch(0, this.per_page, "id", "asc");
  }

  public doFilter() {
    this.doSearch(0, this.per_page, "id", "asc", this.filterProduct);
  }

  public doSearch(offset?: number, limit?: number, order?: string, sort?: string, filter?: Record<string, unknown>) {
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.max_per_page) {
      limit = this.max_per_page;
    }

    this.$store
      .dispatch("product/list", { offset, limit, order, sort, filter })
      .then(() => {
        this.table.sortable.order = order;
        this.table.sortable.sort = sort;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public clear() {
    this.filterProduct = {
      id_category: null,
      type: null,
      priceIni: null,
      priceEnd: null,
      description: null,
      name: null,
      code_video_external: null,
    };
    this.doSearch(0, this.per_page, "id", "asc");
  }

  public excluir(id: string | number) {
    this.$store
      .dispatch("product/delete", id)
      .then(() => {
        this.table.isLoading;
      })
      .catch(() => {
        this.errorMessage = t("labels.errorMessageDelete");
      });
  }

  public formatNumber(value) {
    return value ? Number(value).toFixed(2) : "-";
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: t("labels.entity.id"),
      field: "id",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.category"),
      field: "category.description",
      sortable: true,
      isKey: true,
    },
    {
      label: t("labels.entity.type"),
      field: "product_type.description",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.price"),
      field: "price",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.discount"),
      field: "discount",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.is_highlight"),
      field: "is_highlight",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.video_display_name"),
      field: "video_display_name",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      label: t("labels.entity.active"),
      field: "enabled",
      sortable: true,
      columnStyles: "text-align: center",
    },
    {
      field: "actions",
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: "id",
    sort: "asc",
  },
});
