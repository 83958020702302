import { Options } from "vue-class-component";

import config from "@/config/config";
import PageBaseView from "@/pages/PageBaseView";
import { Order } from "@/domain/order.model";
import { PaymentAttempt } from "@/domain/payment-attempt.model";
import { reactive } from "vue";
import i18n from "@/translation/i18n";
import { UtilDate } from "@/helpers/date";
const { t } = i18n.global;

@Options({
  name: "order-view",
  props: {
    id: String,
  },
})
export default class OrderView extends PageBaseView {
  public paymentAttempts: PaymentAttempt[] = reactive([]);
  public showCancelOrder = false;
  public msgCancelOrder = "";
  public row: Order = {
    id_customer: 0,
    id_payment: 0,
    amount: 0,
    expire_in: new Date(),
    status: "",
  };

  public formatDate(date: string | Date): string {
    return date ? UtilDate.format(date, "DD/MM/YYYY") : "";
  }

  public formatNumber(value) {
    return value ? Number(value).toFixed(2) : "-";
  }

  public mounted(): void {
    this.url = "order";
    this.module = config.modules.order;

    this.loadOrder();
  }

  public loadOrder(): void {
    this.$store
      .dispatch("order/get", this.id)
      .then((res: Order) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = t("labels.errorMessage");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.errorMessage");
      });
  }

  public cancelOrder(customer) {
    this.showCancelOrder = true;
    this.msgCancelOrder = t("labels.msgCancelOrder") + ` ${customer}`;
  }

  public confirmCancel() {
    this.$store
      .dispatch("order/cancelOrder", this.id)
      .then(() => {
        this.loadOrder();
        this.closeModal();
      })
      .catch(() => {
        this.errorMessage = t("labels.errorMessage");
      });
  }

  public closeModal() {
    this.showCancelOrder = false;
  }
}
