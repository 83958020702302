import { SystemParam } from "@/domain/system-param.model";
import { IPaginateVueTable } from "@/interfaces/IPaginateVueTable";

export default {
  systemParams: (state): IPaginateVueTable<SystemParam> => state.systemParams,
  systemParam: (state): SystemParam => state.systemParam,
  isLoading: (state): boolean => state.isLoading,
  isCreating: (state): boolean => state.isCreating,
  isUpdating: (state): boolean => state.isUpdating,
  isDeleting: (state): boolean => state.isDeleting,
};
