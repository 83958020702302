import { Options, Vue } from "vue-class-component";
import { useToast } from "vue-toastification";
import { useReCaptcha } from "vue-recaptcha-v3";

import AuthService from "@/services/auth.service";
import Checkbox from "@/components/checkbox/checkbox.vue";
import Input from "@/components/input/input.vue";
import Button from "@/components/button/button.vue";

@Options({
  components: {
    "app-checkbox": Checkbox,
    "app-input": Input,
    "app-button": Button,
  },
})
export default class Login extends Vue {
  private appElement: HTMLElement | null = null;
  public email: string = "";
  public password: string = "";
  public rememberMe: boolean = false;
  public isAuthLoading: boolean = false;
  public isFacebookLoading: boolean = false;
  public isGoogleLoading: boolean = false;
  private toast = useToast();
  private recaptcha = useReCaptcha();

  public mounted(): void {
    this.appElement = document.getElementById("app") as HTMLElement;
    this.appElement.classList.add("login-page");
  }

  public unmounted(): void {
    (this.appElement as HTMLElement).classList.remove("login-page");
  }

  public async getRecaptcha() {
    await this.recaptcha.recaptchaLoaded();

    return await this.recaptcha.executeRecaptcha("login");
  }

  public async loginByAuth(): Promise<void> {
    try {
      this.isAuthLoading = true;

      const tokenReCaptcha = await this.getRecaptcha();

      if (tokenReCaptcha) {
        const token = await AuthService.loginByAuth(this.email, this.password, tokenReCaptcha);

        this.$store.dispatch("auth/login", token.token);
        this.$store.dispatch("auth/getExpiresIn", token.expires_in);
        this.$store.dispatch("auth/getUser", token.user);
        this.$store.dispatch("auth/getModules", token.modules);

        this.toast.success("Login succeeded");
      }

      this.isAuthLoading = false;
    } catch (error) {
      const message = error?.response?.data?.message || error.message || "Failed";
      this.toast.error(message);
      this.isAuthLoading = false;
    }
  }
}
