import { Options, Vue } from "vue-class-component";

import List from "@/pages/config/users/list/list.vue";

@Options({
  name: "users",
  components: {
    "page-list": List,
  },
})
export default class Users extends Vue {
  public isIndexOnly() {
    return /^[/]?users[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
