import Toggle from "@vueform/toggle";
import { Money } from "v-money";
import { Options, Vue } from "vue-class-component";

import { IList } from "@/domain/list.model";
import { ProductCategory } from "@/domain/product-category.model";
import { ProductType } from "@/domain/product-type.model";
import { Product } from "@/domain/product.model";
import { formatNumber } from "@/helpers/numbers";
import { ErrorType } from "@/interfaces/error";
import i18n from "@/translation/i18n";
import { reactive } from "vue";

const { t } = i18n.global;

@Options({
  name: "product-edit",
  props: {
    id: String,
  },
  components: {
    Toggle,
    Money,
  },
})
export default class ProductEdit extends Vue {
  public id: string;
  public errorMessage = "";
  public errors: ErrorType = {};
  public hasError = false;
  public categorys: IList[] = reactive([]);
  public types: IList[] = reactive([]);
  public money = {
    decimal: ".",
    thousands: "",
    precision: 2,
    masked: false,
  };

  public row: Product & { priceFormated?: string; discountFormated?: string } = {
    id_category: 0,
    type: 0,
    price: 0,
    discount: 0,
    description: "",
    image: "",
    enabled: false,
    priceFormated: "",
    discountFormated: "",
    is_highlight: false,
    image_highlight: "",
    platform_video_name: "",
    video_display_name: "",
    isFree: false,
  };

  public mounted(): void {
    this.$store
      .dispatch("product/get", this.id)
      .then((res: Product) => {
        if (res && res.id) {
          this.row = res;
          this.row.priceFormated = res.price ? formatNumber(res.price) : "";
          this.row.discountFormated = res.discount ? formatNumber(res.discount) : "";
        } else {
          this.errorMessage = t("labels.noDataAvailable");
        }
      })
      .catch(() => {
        this.errorMessage = t("labels.noDataAvailable");
      });
  }

  public created(): void {
    this.$store.dispatch("productCategory/listActive").then((res: ProductCategory[]) => {
      this.categorys = res.map(i => {
        return { id: i.id, description: i.description };
      });
    });
    this.$store.dispatch("productType/listActive").then((res: ProductType[]) => {
      this.types = res.map(i => {
        return { id: i.id, description: i.description };
      });
    });
  }

  public goBack() {
    this.$router.push("/product");
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.id_category) {
      this.errors.id_category = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.type) {
      this.errors.type = t("labels.errors");
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = t("labels.errors");
      this.hasError = true;
    }

    if (this.row.priceFormated && this.row.discountFormated) {
      const price = parseFloat(this.row.priceFormated);
      const discount = parseFloat(this.row.discountFormated);

      if (discount > price) {
        this.errors.discount = t("labels.errosDiscount");
        this.hasError = true;
      }
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      this.$store
        .dispatch("product/update", {
          id: parseInt(this.id),
          id_category: this.row.id_category,
          type: this.row.type,
          price: this.row.priceFormated ? parseFloat(this.row.priceFormated) : undefined,
          discount: this.row.discountFormated ? parseFloat(this.row.discountFormated) : undefined,
          description: this.row.description,
          image: this.row.image,
          is_highlight: this.row.is_highlight,
          image_highlight: this.row.image_highlight,
          platform_video_name: this.row.platform_video_name,
          video_display_name: this.row.video_display_name,
          enabled: this.row.enabled,
          isFree: this.row.isFree,
        })
        .then(() => {
          this.$router.push("/product");
        })
        .catch(() => {
          this.errorMessage = t("labels.errorMessage");
        });
    }
  }
}
