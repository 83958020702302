import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const paymentAttemptModule = {
  namespaced: true,
  state: {
    paymentAttempts: null,
    paymentAttempt: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  mutations,
  actions,
  getters,
};

export default paymentAttemptModule;
