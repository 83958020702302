import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67b95734"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "right fas fa-angle-left"
}
const _hoisted_2 = { class: "nav nav-treeview" }
const _hoisted_3 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "right fas fa-angle-left"
}
const _hoisted_6 = {
  class: "nav nav-treeview",
  style: {"padding-left":"20px"}
}
const _hoisted_7 = { class: "nav-item" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["nav-item", { 'menu-open': _ctx.isMenuExtended[_ctx.menuItem.path] }])
  }, [
    _createElementVNode("a", {
      class: _normalizeClass(["nav-link", { 'active': _ctx.isMainActive || _ctx.isOneOfChildrenActive }]),
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.handleMainMenuAction(_ctx.menuItem)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["nav-icon", _ctx.$t(_ctx.menuItem.icon)])
      }, null, 2),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.menuItem.name)), 1),
      (_ctx.isExpandable)
        ? (_openBlock(), _createElementBlock("i", _hoisted_1))
        : _createCommentVNode("", true)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItem.children, (item) => {
      return (_openBlock(), _createElementBlock("ul", _hoisted_2, [
        (!item.children)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: item.path,
                class: "nav-link",
                exact: "",
                "exact-active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass(["nav-icon", item.icon ? _ctx.$t(item.icon) : ''])
                  }, null, 2),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(item.name)), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true),
        (item.children)
          ? (_openBlock(), _createElementBlock("li", {
              key: 1,
              class: _normalizeClass(["nav-item", { 'menu-open': _ctx.isMenuExtended[item.path] }])
            }, [
              _createElementVNode("a", {
                class: _normalizeClass(["nav-link", { 'active': _ctx.isMainActive || _ctx.isOneOfChildrenActive }]),
                onClick: $event => (_ctx.handleMainMenuAction(item))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["nav-icon", item.icon ? _ctx.$t(item.icon) : ''])
                }, null, 2),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(item.name)), 1),
                (_ctx.isExpandable)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_4),
              _createElementVNode("ul", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (item2) => {
                  return (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createVNode(_component_router_link, {
                      to: item2.path,
                      class: "nav-link",
                      exact: "",
                      "exact-active-class": "active"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("i", {
                          class: _normalizeClass(["nav-icon", item2.icon ? _ctx.$t(item2.icon) : ''])
                        }, null, 2),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(item2.name)), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 256))
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]))
    }), 256))
  ], 2))
}