import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const productLanguageModule = {
  namespaced: true,
  state: {
    productLanguages: null,
    productLanguage: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  mutations,
  actions,
  getters,
};

export default productLanguageModule;
