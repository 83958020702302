import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const systemParamModule = {
  namespaced: true,
  state: {
    systemParams: null,
    systemParam: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  mutations,
  actions,
  getters,
};

export default systemParamModule;
