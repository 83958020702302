import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const reportsModule = {
  namespaced: true,
  state: {
    isLoading: false,
    reportCustomerNewsletter: null,
    reportCustomerNewsletterExport: null,
    reportProduct: null,
    reportProductExport: null,
    reportUserRegister: null,
    reportUserRegisterExport: null,
  },
  mutations,
  actions,
  getters,
};

export default reportsModule;
