export default {
  setProductTypes: (state, productTypes) => {
    state.productTypes = productTypes;
  },
  setProductType: (state, productType) => {
    state.productType = productType;
  },
  setProductTypeIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setProductTypeIsCreating: (state, isCreating) => {
    state.isCreating = isCreating;
  },
  setProductTypeIsUpdating: (state, isUpdating) => {
    state.isUpdating = isUpdating;
  },
  setProductTypeIsDeleting: (state, isDeleting) => {
    state.isDeleting = isDeleting;
  },
};
