import { createStore } from "vuex";

import authModule from "./auth";
import uiModule from "./ui";

import productCategoryModule from "./modules/product-category";
import customerModule from "./modules/customer";
import faqModule from "./modules/faq";
import newsletterModule from "./modules/newsletter";
import orderModule from "./modules/order";
import paymentTypeModule from "./modules/payment-type";
import platformModule from "./modules/platform";
import productTypeModule from "./modules/product-type";
import productModule from "./modules/product";
import termsUseModule from "./modules/terms-use";
import paymentModule from "./modules/payment";
import paymentAttemptModule from "./modules/payment-attempt";
import usersModule from "./modules/users";
import loginHistoryModule from "./modules/login-history";
import productLanguageModule from "./modules/product-language";
import reportsModule from "./modules/reports";
import systemParamModule from "./modules/system-param";

export default createStore({
  modules: {
    auth: authModule,
    ui: uiModule,
    productCategory: productCategoryModule,
    customer: customerModule,
    faq: faqModule,
    newsletter: newsletterModule,
    order: orderModule,
    paymentType: paymentTypeModule,
    platform: platformModule,
    productType: productTypeModule,
    product: productModule,
    termsUse: termsUseModule,
    payment: paymentModule,
    paymentAttempt: paymentAttemptModule,
    users: usersModule,
    loginHistory: loginHistoryModule,
    productLanguage: productLanguageModule,
    reports: reportsModule,
    systemParam: systemParamModule,
  },
});
